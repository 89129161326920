/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakService } from 'keycloak-angular';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { BOSCH_IMAGE } from '../constants/strings';
import { EnvService } from 'src/app/env.service';
import { WebSocketService } from 'src/app/providers/WebSocketService';
import { AgentControllerService } from 'src/app/services/Whatsapp-agent/services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
/**
  * @ngdoc component
  * @name SharedModule.component:Header
  *
  *
  * @description
  * description: To render the header throughout the application
  *
*/
export class HeaderComponent implements OnInit {

  @Input() public loggedInUserProfile;
  @Output() public sidenavToggle = new EventEmitter();
  routerUrl: any;
  showHeaderopt = true;

  public languages: any = [
    { id: 'de', name: 'Deutsch' },
    { id: 'en', name: 'English' }
  ];
  agentId: string;
  secretKey: any;

  constructor(private router: Router, private translate: TranslateService,
    private _matDialog: MatDialog,
    protected keycloakAngular: KeycloakService, private http: HttpClient,
    private webSocketService: WebSocketService,
    private agentControllerService:AgentControllerService,
    private env: EnvService) { }

  defaultlang = 'En';
  showSearchBar = false;
  profilejson: any;
  helpjson: any;
  langjson: any;
  taboptionjson: any;
  loginStatus;
  boschImage;

  ngOnInit() {
    this.agentId = this.keycloakAngular.getKeycloakInstance().subject;
    this.loginStatus = this.keycloakAngular.isLoggedIn().then(() => true, () => false);
    this.http.get('./assets/header/profile.json')
      .subscribe(profdata => {
        this.profilejson = profdata;
      },
        err => {
          console.log('Error in fetching profile json file.');
        });

    this.http.get('./assets/header/help.json')
      .subscribe(helpdata => {
        this.helpjson = helpdata;
      },
        err => {
          console.log('Error in fetching help json file.');
        });

    this.http.get('./assets/header/language-dropdown.json')
      .subscribe(langdata => {
        this.langjson = langdata;
      },
        err => {
          console.log('Error in fetching language json file.');
        });

    this.http.get('./assets/header/tab-options.json')
      .subscribe(tabopt => {
        this.taboptionjson = tabopt;
      },
        err => {
          console.log('Error in fetching role json file.');
        });

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.routerUrl = evt.url;
        if (this.routerUrl.indexOf('/terms-and-conditions') !== -1) {
          this.showHeaderopt = false;
        }
      }
    });
    this.boschImage = BOSCH_IMAGE;
    const roles = this.keycloakAngular.getKeycloakInstance()?.realmAccess?.roles;
    if (roles && (roles.includes('superAgent') || roles.includes('agent'))) {
      this.getSecretKey();
    }
  }

  onLogoClicked() {
  }

  /**
    * @ngdoc method
    * @name Header#gotoModal
    *
    * @methodOf
    * SharedModule.controller:Header
    *
    * @description
    * Description: when the logout icon is clicked popover appers with logout and cancel button.
    *
    * @param {type} name description
    * @return {type} name description
  */
  gotoModal(content) {
    this._matDialog.open(content);
  }

  /**
    * @ngdoc method
    * @name Header#logout
    *
    * @methodOf
    * SharedModule.controller:Header
    *
    * @description
    * Description: implementation of logout functionality.
    *
    * @param {type} name description
    * @return {type} name description
  */
  logout() {
    const hasAgentRole = this.loggedInUserProfile.roles.includes('agent');
    if(hasAgentRole){
      this.webSocketService.disConnectSocket(this.agentId,this.secretKey);
    }
    this.keycloakAngular.logout(this.env.apiUrl);
  }

  /**
    * @ngdoc method
    * @name Header#cancel
    *
    * @methodOf
    * SharedModule.controller:Header
    *
    * @description
    * Description: when the cancel button is clicked, it returns to the application without logging off.
    *
    * @param {type} name description
    * @return {type} name description
  */
  cancel() {
    this._matDialog.closeAll();
  }

  /**
    * @ngdoc method
    * @name Header#onSearchSelect
    *
    * @methodOf
    * SharedModule.controller:Header
    *
    * @description
    * Description: when the search icon is clicked, it displays the search bar.
                   Future Implementation - Search Bar
    * @param {type} name description
    * @return {type} name description: displays the result of the searched text.
  */

  //   onSearchSelect() {
  //     this.showSearchBar = !this.showSearchBar;
  //   }

  optionSelect(tile, content?) {
    if (tile.menuItem === 'signOut') {
      this.gotoModal(content);
    } else {
      this.keycloakAngular.getKeycloakInstance().accountManagement();
    }
  }

  /**
    * @ngdoc method
    * @name Header#selectLanguage
    *
    * @methodOf
    * SharedModule.controller:Header
    *
    * @description
    * Description: Translates the whole application into selected language.
    *
    * @param {type} name description: language selected.
    * @return {type} name description: changes the application language to the selected language
  */

  selectLanguage(languageItem) {
    this.translate.use(languageItem.toString());
    this.defaultlang = languageItem;
  }
  getSecretKey() {
    this.agentControllerService.getSecretKey().subscribe( resp=>{
     this.secretKey =resp;
    });
  }
}
