import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import {
  USER_IMAGE,
  WHATSAPP_ICON,
  SEND_CHAT,
  PDF_IMG,
  DOWNLOAD_IMG
} from 'src/app/shared/constants/strings';
import { AgentControllerService, DepartmentControllerService } from 'src/app/services/Whatsapp-agent/services';
import { PlatformAdminUtilityControllerService } from 'src/app/services/Platform/services';
import { IncomingMessageControllerService } from 'src/app/services/Whatsapp-agent/services';
import { map } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { MatDialog } from '@angular/material/dialog';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { BciLoaderService } from '@bci-web-core/core';
import { Observable } from 'rxjs';
import { AgentRequestDto, } from 'src/app/services/Whatsapp-agent/models';
import { WebSocketService } from 'src/app/providers/WebSocketService';

@Component({
  selector: 'app-web-chat',
  templateUrl: './web-chat.component.html',
  styleUrls: ['./web-chat.component.scss'],
})
export class WebChatComponent implements OnInit, OnDestroy {
  secretKey:string;
  @ViewChild('chatBox') private chatBox!: ElementRef;
  whatsappIcon = WHATSAPP_ICON;
  user = USER_IMAGE;
  chatSendIcon = SEND_CHAT;
  pdfIcon = PDF_IMG;
  downloadIcon = DOWNLOAD_IMG;
  chatPageVisible: boolean = false;
  userNameVisible: boolean = true;
  loading: boolean = false;
  selectedValue: string = 'Open';
  selectedChatMessages = [];
  chatList = [];
  chatMessage = {};
  userName: any;
  userStatus: any;
  selectedChat: any = null;
  messageInput: string = '';
  selectedChatId: string;
  chatRoomId: any;
  wabaNumber: any;
  phoneNumber: any;
  agentName: any;
  agentId: string;
  deptId: string;
  page: number = 0;
  limit: number = 10;
  totalMessages: number = 0;
  allStatuse = ['Open Chats', 'Closed Chats', 'All Chats'];
  allChatStatus = ['Open', 'Close', 'Transfer Chat'];
  selectedStatus: string;
  unReadCount: any;
  unReadCounts: { [key: string]: number } = {};
  chatRoomPage: number = 0;
  totalChatRooms: any;
  isInputDisabled: boolean = false;
  selectedChatRoom: string;
  dropDownValues: any = {};
  channelNumber: any;
  private isTabActive = true;
  openStatus = 'Open Chats';
  showEmojiPicker = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ];
  set = 'twitter';
  public supportedMediaType;
  public videoFormat;
  public imageFormat;
  public documentFormat;
  public audioFormat;
  public fileName;
  public fileForUpload;
  public fileUrl;
  public showSpinner: any;
  public mediaURL: string;
  public agentList: any;
  // Super Agent
  public permissionList: Array<String> = [];
  superAgentLogin: boolean = false;
  wabaNumberAgent: any;
  noChats: boolean = false;
  selectedDepartment: string;
  selectedAgent: string;
  selectedChats = 'All Chats';
  selectedAssignment = false;
  allDepartments: any;
  allChats: Array<{ name: string; value: string }> = [
    { name: 'All Chats', value: 'All Chats' },
    { name: 'Open Chats', value: 'Open Chats' },
    { name: 'Closed Chats', value: 'Closed Chats' },
    { name: 'UnAssigned Chats', value: 'UnAssigned Chats' },
  ];
  chatRoomStatus = undefined;
  agentLists: { id: string; name: string; }[];
  modalTitle = 'TRANSFER_AGENT';
  messageFileInput: string = '';
  fromAgentName: any;
  agentLoginName: string;

  constructor(
    private agentControllerService: AgentControllerService,
    private incomingMessageControllerService: IncomingMessageControllerService,
    private departmentControllerService: DepartmentControllerService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private keyClock: KeycloakService,
    private _matDialog: MatDialog,
    private responseHandlerService: ResponseHandlerService,
    private loaderService: BciLoaderService,
    private webSocketService: WebSocketService,
  ) { }

  ngOnInit(): void {
    this.agentId = this.keyClock.getKeycloakInstance().subject;
    this.selectedStatus = this.allStatuse[0];
    this.selectedChatRoom = this.allChatStatus[0];
    this.getAgentDetails();
  }

  getAgentDetails() {
    this.platformAdminUtilityControllerService
      .getUserAccountDetails().subscribe(
        (resp) => {
          if (resp) {
            this.getSecretKey();
            this.agentLoginName = resp['firstName'];
            this.permissionList = resp['permissions'];
            this.wabaNumberAgent = resp['wabaNumber'];
            this.getDeptsByChannel(this.wabaNumberAgent);
            if (this.checkPermission('WSP_DEPT_C')) {
              this.superAgentLogin = true;
              this.connect(this.wabaNumberAgent);
              this.loadChatRooms('channel', this.wabaNumberAgent);
              this.allChatStatus = ['Open', 'Close', 'Assign Chat'];
              this.modalTitle = 'ASSIGN_CHAT';
            } else {
              if (this.agentId) {
                this.connect(this.agentId);
                this.initializeEventListeners();
                this.loadChatRooms('agent', this.agentId, true);
              }
            }
          }
        },
        (err) => {
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  onStatusChange(event: any): void {
    this.totalChatRooms = 0;
    this.chatList = [];
    this.chatRoomPage = 0;
    this.openStatus = event.value;
    if (event.value === 'Open Chats') {
      this.loadChatRooms('agent', this.agentId, true);
    } else if (event.value === 'Closed Chats') {
      this.loadChatRooms('agent', this.agentId, false);
    } else {
      this.loadChatRooms('agent', this.agentId);
    }
  }

  selectChat(data) {
    if (data.id && this.webSocketService.isSocketConnected()) {
      if (data.unReadCount > 0) {
        this.webSocketService.getClearUnReadMsgCount(data.id,this.secretKey);
        this.updateChatListUnreadCount(data.id, null);
      }
    }
    this.messageInput = '';
    this.selectedChatRoom = this.allChatStatus[0];
    this.channelNumber = data.wabaNumber;
    const userNameOrPhone = data.userName || data.phoneNumber;
    this.userName = userNameOrPhone;
    this.isInputDisabled = !data.active;
    this.chatRoomId = data.id;
    this.wabaNumber = data.wabaNumber;
    this.phoneNumber = data.phoneNumber;
    this.selectedChatId = data.id;
    this.page = 0;
    this.selectedChatMessages = [];
    this.fromAgentName = data.agentName; 
    this.totalMessages = 0;
    if (this.chatBox) {
      this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight;
    }
    this.loadMessages();
  }

  getCurrentTime(): string {
    const now = new Date();
    let hours = now.getHours();
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const amPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format
    return `${hours}:${minutes} ${amPm}`;
  }

  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.child !== undefined && $event.child === 'agentId') {
        const departmentId = $event.value === 'Default' ? this.wabaNumberAgent :  $event.value;
        this.getAgentsByChannel(this.channelNumber, departmentId, true);
      }
    }
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event) {
    const currentMessage = this.messageInput;
    this.showEmojiPicker = false;
    this.messageInput = `${currentMessage}${event.emoji.native}`;
  }

  public OpenUploadDialog(content) {
    this._matDialog.open(content, { disableClose: true });
    this.fileForUpload = null;
    this.fileName = '';
  }

  public selectFiles(event) {
    this.fileForUpload = event.target.files[0];
    if (this.fileForUpload) {
      this.fileName = this.fileForUpload.name;
      if (this.fileForUpload.type === 'video/mp4') {
        this.supportedMediaType = 'VIDEO';
      } else if (this.fileForUpload.type === 'image/jpeg'|| this.fileForUpload.type === 'image/png') {
        this.supportedMediaType = 'IMAGE';
      } else if (this.fileForUpload.type === 'application/pdf') {
        this.supportedMediaType = 'DOCUMENT';
      } else if (this.fileForUpload.type === 'audio/mpeg') {
        this.supportedMediaType = 'AUDIO';
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.fileUrl = reader.result as string;
      };
      reader.readAsDataURL(this.fileForUpload);
    }
  }

  public fileUpload() {
    this.onClose();
    let reqObj;
    if (this.fileUrl) {
      this.showSpinner = this.loaderService.showProgressBar();
      const params = {
        apiKey: this.secretKey,
        body: {
          file: this.fileForUpload
        }
      };
      reqObj = params;
      this.incomingMessageControllerService.uploadFileAndReturnUrl(reqObj).subscribe(
        (resp) => {
          if (resp && resp['length']) {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.mediaURL = resp;
            if (this.selectedChatId !== null) {
              const agentDetails = {
                wabaNumber: this.wabaNumber,
                phoneNumber: this.phoneNumber,
                content: this.messageFileInput,
                mediaUrl: this.mediaURL,
                mediaType: this.supportedMediaType,
                fromAgent: true
              };
              this.webSocketService.sendChat(agentDetails,this.secretKey);
              this.displayMessage(
                {
                  ...agentDetails,
                  chatRoomId: this.selectedChatId,
                },
                true
              );
              this.scrollToBottom();
              this.messageFileInput = '';
            }
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
    }
  }


  downloadPDF(event, fileURL) {
    fetch(fileURL)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const FileSaver = require('file-saver');
        FileSaver.saveAs(blob, 'Sample.pdf');
      })
      .catch(error => {
        this.responseHandlerService.returnToastMessage(
          'error',
          error.error.message
        );
      });
  }

  /**
   * @ngdoc method
   * @name WebChatComponent#scrollToBottom
   *
   * @methodOf WebChatComponent
   *
   * @description
   * Scrolls the chat box to the bottom. This ensures that the most recent messages are visible.
   *
   * @param {void} null - No parameters required.
   * @returns {void} null - This method does not return any value.
   */
  private scrollToBottom(): void {
    try {
      const chatBox = this.chatBox.nativeElement;
      setTimeout(() => {
        chatBox.scrollTop = chatBox.scrollHeight - chatBox.clientHeight;
      }, 50);
    } catch (err) {
      console.error('Scroll to bottom failed:', err);
    }
  }

  connect(agentId): void {
    if (agentId) {
      this.webSocketService.connectSocket(agentId,this.secretKey).subscribe(
        (message) => this.onMessageReceived(message),
        (error) => this.onError(error)
      );
    } else {
      console.error('Agent Id not found');
    }
  }

  onMessageReceived(message: any) {
    if (message.action == 'getUnReadCount') {
      this.unReadCounts[message.data.chatRoomId] = message.data.unReadCount;
      this.updateChatListUnreadCount(
        message.data.chatRoomId,
        message.data.unReadCount,
      );
    } else if (message.action == 'transfer') {
      if (this.agentId == message.userId) {
        this.callChatRoom();
      }
    } else {
      if(this.wabaNumberAgent){
        if (this.wabaNumberAgent == message.userId) {
          this.isInputDisabled = false;
        this.checkAndAddChatRoom(message);
      }}
      if (this.agentId == message.userId) {
        this.isInputDisabled = false;
        this.checkAndAddChatRoom(message);
      }
      this.displayMessage(message.data, false);
      this.scrollToBottom();
    }
  }

  private updateChatListUnreadCount(chatId: string, unReadCount: number, active?): void {
    const chatIndex = this.chatList.findIndex((c) => c.id === chatId);
    if (chatIndex !== -1) {
      const chat = this.chatList[chatIndex];
      chat.unReadCount = unReadCount;
      chat.active = true;
      this.chatList.splice(chatIndex, 1);
      this.chatList.unshift(chat);
    }
  }

  /**
 * @ngdoc method
 * @name WebChatComponent#checkAndAddChatRoom
 *
 * @methodOf WebChatComponent
 *
 * @description
 * Checks if a chat room already exists in the chat list. If not, it adds a new chat room to the top of the list.
 *
 * @param {Object} message - The message object containing details like data.chatRoomId, data.wabaNumber, data.phoneNumber, and data.userName.
 * @returns {void} null - This method does not return any value.
 */
  checkAndAddChatRoom(message: any) {
    const chatRoomExists = this.chatList.some(chatRoom => chatRoom.id === message.data.chatRoomId);
    if (!chatRoomExists) {
      const newChatRoom = {
        id: message.data.chatRoomId,
        wabaNumber: message.data.wabaNumber,
        phoneNumber: message.data.phoneNumber,
        userName: message.data.userName,
        active: true
      };
      this.chatList.unshift(newChatRoom);
    }
  }

  /**
   * @ngdoc method
   * @name WebChatComponent#displayMessage
   *
   * @methodOf WebChatComponent
   *
   * @description
   * Displays a message in the chat box. Formats the timestamp and pushes the message into the selected chat messages array.
   *
   * @param {Object} message - The message object containing details like wabaNumber, phoneNumber, fromAgent, content, userName, and timestamp.
   * @param {boolean} fromAgent - Indicates if the message is from the agent.
   * @returns {void} null - This method does not return any value.
   */
  displayMessage(message: any, fromAgent: boolean) {
    if (message.chatRoomId) {
      this.webSocketService.getUnreadCount(message.chatRoomId,this.secretKey);
    }
    if (message.chatRoomId && this.openStatus == 'Closed Chats') {
      this.isInputDisabled = false;
      this.selectedStatus = this.allStatuse[0];
      this.callChatRoom();
    }
    if (message.chatRoomId && this.openStatus !== 'Closed Chats') {
      this.isInputDisabled = false;
    }
    let time;
    if (message.lastUpdatedTime) {
      time = this.formatTimestamp(message.lastUpdatedTime);
    } else {
      time = this.getCurrentTime();
    }
 if (message.chatRoomId === this.selectedChatId) {
      const mess = {
        wabaNumber: message.wabaNumber,
        phoneNumber: message.phoneNumber,
        fromAgent: fromAgent,
        content: message.content,
        userName: message.userName,
        time: time,
        mediaUrl: message.mediaUrl,
        mediaType: message.mediaType,
        systemMessage:message.systemMessage
      };
      this.selectedChatMessages.push(mess);
      this.scrollToBottom();
    }
  }

  onError(error: any) {
    if (this.isTabActive) {
      if (this.checkPermission('WSP_DEPT_C')) {
        this.connect(this.wabaNumberAgent);
      } else {
        this.connect(this.agentId);
      }
    }
  }

  formatTimestamp(timestamp) {
    let date;
    if (typeof timestamp === 'string') {
      date = new Date(timestamp);
    } else {
      date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    }
    const time12 = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true // Use 12-hour format with AM/PM
    });
    return time12;
  }

  /**
   * @ngdoc method
   * @name WebChatComponent#sendChatAgent
   *
   * @methodOf WebChatComponent
   *
   * @description
   * Sends a chat message from the agent. Trims the input message, constructs the agent details, and sends the message using the agentControllerService. If the selected chat ID is valid, it also displays the message in the chat box.
   *
   * @returns {void} null - This method does not return any value.
   */
  sendChatAgent() {
    const trimmedMessage = this.messageInput.trim();
    if (trimmedMessage) {
      const agentDetails = {
        wabaNumber: this.wabaNumber,
        phoneNumber: this.phoneNumber,
        content: trimmedMessage,
        fromAgent: true,
      };
      if (this.selectedChatId !== null) {
        this.webSocketService.sendChat(agentDetails,this.secretKey);
        this.displayMessage(
          {
            ...agentDetails,
            chatRoomId: this.selectedChatId,
          },
          true
        );
        this.scrollToBottom();
        this.messageInput = '';
      }
    }
  }

  onScrollUp() {
    this.loadMessages();
  }

  /**
   * @ngdoc method
   * @name WebChatComponent#onScrollDown
   *
   * @methodOf WebChatComponent
   *
   * @description
   * Triggered when the user scrolls down. It loads additional chat rooms.
   *
   * @returns {void} null - This method does not return any value.
   */
  onScrollDown() {
    if (this.checkPermission('WSP_DEPT_C')) {
      this.selectedChats = 'Open Chats';
      this.loadChatRooms('channel', this.wabaNumberAgent);
    } else {
      this.loadChatRooms('agent',this.agentId, true);
    }
  }

  /**
   * @ngdoc method
   * @name WebChatComponent#loadMessages
   *
   * @methodOf WebChatComponent
   *
   * @description
   * Loads messages for the selected chat. It checks if messages are already loading or if all messages have been loaded.
   * If not, it fetches the chat history and updates the selected chat messages.
   *
   * @returns {void} null - This method does not return any value.
   */
  loadMessages() {
    if (this.loading) {
      return;
    }
    if (
      this.selectedChatMessages.length >= this.totalMessages &&
      this.totalMessages !== 0
    ) {
      return;
    }
    this.loading = true;
     const currentScrollHeight = this.chatBox.nativeElement.scrollHeight;
     const currentScrollTop = this.chatBox.nativeElement.scrollTop;
     const scrollPositionFromBottom = currentScrollHeight - currentScrollTop;
    this.getChatHistory(this.selectedChatId, this.page, this.limit).subscribe(
      (resp: any) => {
        const messages = resp.chatMessagesList || [];
        if (messages.length > 0) {
          this.selectedChatMessages = [
            ...messages,
            ...this.selectedChatMessages,
          ];
          this.page++;
        }
        this.totalMessages = resp.totalChatMessages;
        this.loading = false;
        setTimeout(() => {
          const newScrollHeight = this.chatBox.nativeElement.scrollHeight;
          this.chatBox.nativeElement.scrollTop = newScrollHeight - scrollPositionFromBottom;
        }, 0);
      },
      (error) => {
        console.error('Error loading messages:', error);
        this.loading = false;
      }
    );
  }

  /**
   * @ngdoc method
   * @name WebChatComponent#getChatHistory
   *
   * @methodOf WebChatComponent
   *
   * @description
   * Retrieves the chat history for a specific chat room. The history is paginated based on the provided page and limit parameters.
   *
   * @param {string} id - The ID of the chat room.
   * @param {number} page - The page number for pagination.
   * @param {number} limit - The number of messages to retrieve per page.
   * @returns {Observable<Object>} - An observable containing the total chat messages and a list of formatted chat messages.
   */
  getChatHistory(id: string, page: number, limit: number) {
    const params = { chatRoomId: id, page: page, limit: limit };
    return this.agentControllerService.getChatHistory(params).pipe(
      map((resp) => {
        if (resp) {
          return {
            totalChatMessages: resp.totalChatMessages,
            chatMessagesList: resp.chatMessagesList.map((message) => ({
              ...message,
              time: this.formatTimestamp(message.lastUpdatedTime),
            })),
          };
        } else {
          return {
            totalChatMessages: 0,
            chatMessagesList: [],
          };
        }
      })
    );
  }

  closeChat() {
    if (this.selectedChatId) {
      const role = this.superAgentLogin ? 'superAgent' : this.agentName;
      this.webSocketService.getCloseChatRoom(this.selectedChatId, role,this.secretKey);
      this.callChatRoom();
    }
  }

  onChange(content, event: any) {
    if (event.value === 'Close') {
      this.closeChat();
    } else if (event.value === 'Transfer Chat' || event.value === 'Assign Chat') {
      this.getDeptsByChannel(this.channelNumber);
      this._matDialog.open(content, {
        maxHeight: '80vh',
        minWidth: '15vw',
        disableClose: true,
      });
    }
  }

  transferAgent(event) {
    const toAgentName = this.agentList.find((agentName) => agentName.userId === event.agentId);
    const params = {
      chatRoomId: this.chatRoomId,
      agentId: event.agentId,
      fromAgentName: this.fromAgentName || 'superAgent',
      toAgentName: toAgentName?.firstName,
    };
    this.agentControllerService.transferChat(params).subscribe(
      (resp) => {
        this.responseHandlerService.returnToastMessage('success', resp.message);
        this.onClose();
        this.selectedChatRoom = this.allChatStatus[0];
        this.callChatRoom();
      },
      (error) => {
        this.onClose();
      });
  }

  onClose() {
    this._matDialog.closeAll();
  }

  /**
   * @ngdoc method
   * @name WebChatComponent#getAgentsByChannel
   *
   * @methodOf WebChatComponent
   *
   * @description
   * Retrieves a list of agents by channel number, filters out the current agent, and updates the drop-down values for agent selection.
   *
   * @param {string} channelNumber - The channel number to get agents for.
   * @returns {void} null - This method does not return any value.
   */
  getAgentsByChannel(channelNumber, deptId, isForTransfer?) {
    const requestBody: AgentRequestDto = {
      channelNumber: channelNumber, 
    };
    const params = {
      channelNumber: channelNumber,
      departmentId: deptId,
      enable: isForTransfer,
      body: requestBody,
    };
    this.agentControllerService.getAgentsByDepartmentAndChannel(params).subscribe((resp) => {
        this.agentList = resp.agentList;
        const response = resp.agentList
          .filter((item) => item.userId !== this.agentId)
          .map((item) => ({
            id: item.userId,
            name: item.firstName,
            enabled: item.enabled
          }));
        this.agentLists = response;
        this.dropDownValues['agentId'] = response;
      });
  }

  getDeptsByChannel(channelNumber) {
    const body: AgentRequestDto = { channelNumber };
    const params = {
      body,
    };
    this.departmentControllerService.getDepartmentsByChannelNumber(params).subscribe((resp) => {
        const response = resp
          .filter((item) => item.id !== this.deptId)
          .map((item) => ({
            id: item.id,
            name: item.departmentName,
          }));
        this.allDepartments = response;
        this.dropDownValues['deptId'] = response;
      });
  }

  callChatRoom() {
    this.totalChatRooms = 0;
    this.chatList = [];
    this.chatRoomPage = 0;
    if (this.checkPermission('WSP_DEPT_C')) {
      this.selectedChats = 'Open Chats';
      this.loadChatRooms('channel', this.wabaNumberAgent,true);
    } else {
      this.loadChatRooms('agent',this.agentId, true);
    }
  }

  private handleVisibilityChange = () => {
    this.isTabActive = !document.hidden;
    if (this.isTabActive) {
      if (!this.webSocketService.isSocketConnected()) {
        this.connect(this.agentId);
        this.loadChatRooms('agent',this.agentId, true);
      }
    }
  };

  private handleBeforeUnload = (event: BeforeUnloadEvent) => {
    this.webSocketService.disConnectSocket(this.agentId,this.secretKey);
  };

  private initializeEventListeners() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  // Super Agent

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  onDepartmentChange(event: any) {
    if (event.value !== undefined) {
    this.totalChatRooms = 0;
    this.chatList = [];
    this.chatRoomPage = 0;
    if (event.value == 'Default') {
        this.loadChatRooms('department', event.value, this.chatRoomStatus, false,this.channelNumber);
    } else {
        this.loadChatRooms('department', event.value, this.chatRoomStatus);
    } 
    const departmentId = event.value === 'Default' ? this.wabaNumberAgent :  event.value;
    this.getAgentsByChannel(this.wabaNumberAgent, departmentId);
  }
}

  onAgentChange(event: any) {
    if (event.value !== undefined) {
    this.totalChatRooms = 0;
    this.chatList = [];
    this.chatRoomPage = 0;
    this.loadChatRooms('agent', event.value, this.chatRoomStatus);
    }
  }

  onChatChange(event: any) {
    this.totalChatRooms = 0;
    this.chatList = [];
    this.chatRoomPage = 0;
    let assigned: boolean | undefined;

    switch (event.value) {
      case 'Open Chats':
        this.chatRoomStatus = true;
        assigned = undefined;
        break;
      case 'Closed Chats':
        this.chatRoomStatus = false;
        assigned = undefined;
        break;
      case 'UnAssigned Chats':
        this.chatRoomStatus = true;
        assigned = false;
        break;
      default:
        this.chatRoomStatus = undefined;
        assigned = undefined;
    }
    this.selectedAgent = null;
    this.selectedDepartment = null;
    this.loadChatRooms('channel',this.wabaNumberAgent, this.chatRoomStatus, assigned);

  }
  /**
   * @ngdoc method
   * @name WebChatComponent#loadChatRooms
   *
   * @methodOf WebChatComponent
   *
   * @description
   * Retrieves chat rooms based on the type (agent, channel, department). If there are chat rooms available, it selects the first chat room.
   *
   * @param {string} type - The type of chat rooms to load ('agent', 'channel', 'department').
   * @param {string} id - The ID related to the type (agentId, channelNumber, departmentId).
   * @param {boolean} [chatRoomStatus] - Optional status to filter chat rooms.
   * @param {boolean} [assigned] - Optional flag for channel chat rooms.
 * @param {string} [assignedOrChannelNumber] - Optional channelNumber for department chat rooms.
   * @returns {void} null - This method does not return any value.
   */
  loadChatRooms(
    type: 'agent' | 'channel' | 'department',
    id: string,
    chatRoomStatus?: boolean,
    assigned?: boolean,
    assignedOrChannelNumber?:string): void {
    if (
      this.chatList.length >= this.totalChatRooms &&
      this.totalChatRooms !== 0
    ) {
      return;
    }

    const params: any = {
      page: this.chatRoomPage,
      limit: this.limit,
      active: chatRoomStatus,
    };

    let serviceMethod: Observable<any>;

    if (type === 'agent') {
    params.agentId = id;
      serviceMethod = this.agentControllerService.getAgentChatRooms(
        params as {
          agentId: string;
          active?: string;
          page?: number;
          limit?: number;
        }
      );
    } else if (type === 'channel') {
    params.body = { channelNumber: id } as AgentRequestDto; 
    if (assigned !== undefined) {
      params.assigned = assigned;
    }
      serviceMethod = this.agentControllerService.getChannelChatRooms(
        params as {
          active?: string;
          body: AgentRequestDto;
          assigned?: string;
          page?: number;
          limit?: number;
        }
      );
    } else if (type === 'department') {
    params.departmentId = id;
    params.body = { channelNumber: id } as AgentRequestDto;
      serviceMethod = this.departmentControllerService.getAgentChatRooms(
        params as {
          departmentId: string;
          body: AgentRequestDto;
          active?: string;
          page?: number;
          limit?: number;
        }
      );
    } else {
      console.error('Invalid type provided to loadChatRooms');
      return;
    }
    serviceMethod.subscribe(
      (resp: any) => {
        if (resp.chatRoomDetailsList && resp.chatRoomDetailsList.length === 0) {
          this.messageInput = '';
          this.userName = null;
          this.selectedChatMessages = [];
          this.isInputDisabled = true;
          this.loading = false;
          this.noChats = true;
          return;
        }
        const chatRooms = resp.chatRoomDetailsList || [];
        if (chatRooms.length > 0) {
          this.selectChat(chatRooms[0]);
          this.chatList = [...this.chatList, ...chatRooms];
          this.chatRoomPage++;
        }
        this.totalChatRooms = resp.totalChatRooms;
        this.noChats = false; 
      },
      (error) => {
        console.error('Error loading chat rooms:', error);
        this.loading = false;
      }
    );
  }

  openImage(url: string): void {
    window.open(url, '_blank');
  }
  
  ngOnDestroy(): void {
      const id = this.superAgentLogin ? this.wabaNumberAgent : this.agentId;
      this.webSocketService.disConnectSocket(id,this.secretKey);
  }

  getSecretKey() {
    this.agentControllerService.getSecretKey().subscribe( resp=>{
     this.secretKey =resp;
    });
  }

}
