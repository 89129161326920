<section class="overview-container">
    <section class="main">
      <div class="header-style">
        <span class="heading-label">
          <img class="page-icon-small" [src]="whatsappIcon" />
          <span>{{'AGENT_CONFIGURATION' | translate}}</span>
        </span>
        <div class="button-container">
          <button mat-raised-button class="white-button" (click)="viewAgentList()">
            {{'VIEW_AGENT_LIST' | translate}}
          </button>
        </div>
      </div>
      <div class="form-spacing">
        <app-dynamic-form   [jsonFile]="'whatsapp-agent-config'"
          [gridLayout]="'grid-container-whatsapp-agent-config'" [dropDownValues]="dropDownValues"
           (formEmitter)="createAgent($event)" (resetForm)="onReset()"></app-dynamic-form>
      </div>
      </section>
      </section>