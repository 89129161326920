import { Pipe, PipeTransform } from '@angular/core';
import linkifyHtml from 'linkifyjs/html';

@Pipe({
  name: 'linkifyWithNewlines'
})
export class LinkifyWithNewlinesPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (!value) {
      return '';
    }
    let linkifiedValue = linkifyHtml(value);
    return linkifiedValue.replace(/\n/g, '<br>');
  }
}
