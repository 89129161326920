import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { WhatsappReportRequestDto } from '../models/whatsapp-report-request-dto';
import { WhatsappReportResponseDto } from '../models/whatsapp-report-response-dto';
import { WhatsappJobStatusDto } from '../models/whatsapp-job-status-dto';
import { WhatsappJobPhoneNumbersDto } from '../models/whatsapp-job-phone-numbers-dto';
import { WhatsappDeliveryReport } from '../models/whatsapp-delivery-report';
import { TemplateAnalyticsDto } from '../models/template-analytics-dto';
import { WhatsAppLinkTrackingStatusCountDto } from '../models/whats-app-link-tracking-status-count-dto';
import { WhatsAppLinkTrackingDto } from '../models/whats-app-link-tracking-dto';

@Injectable({
  providedIn: 'root',
})
export class WhatsappDeliveryReportControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation downloadWhatsappDeliveryReports
   */
  static readonly DownloadWhatsappDeliveryReportsPath = '/api/v1/delivery-report/excel';

  /**
   * Get SMS delivery report.
   *
   * The API to get Delivery report if records<50
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadWhatsappDeliveryReports()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  downloadWhatsappDeliveryReports$Response(params?: {
    body?: WhatsappReportRequestDto
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappDeliveryReportControllerService.DownloadWhatsappDeliveryReportsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Get Whatsapp delivery report.
   *
   * The API to get Delivery report if records<50
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadWhatsappDeliveryReports$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  downloadWhatsappDeliveryReports(params?: {
    body?: WhatsappReportRequestDto
  }
): Observable<void> {

    return this.downloadWhatsappDeliveryReports$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getWhatsappDeliveryReports
   */
  static readonly GetWhatsappDeliveryReportsPath = '/api/v1/delivery-report';

  /**
   * Get Whatsapp delivery report.
   *
   * The API to get Delivery report if records<50
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWhatsappDeliveryReports()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWhatsappDeliveryReports$Response(params?: {
    
    body?: WhatsappReportRequestDto
  }
): Observable<StrictHttpResponse<WhatsappReportResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappDeliveryReportControllerService.GetWhatsappDeliveryReportsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhatsappReportResponseDto>;
      })
    );
  }

  /**
   * Get Whatsapp delivery report.
   *
   * The API to get Delivery report if records<50
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWhatsappDeliveryReports$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWhatsappDeliveryReports(params?: {
    body?: WhatsappReportRequestDto
  }
): Observable<WhatsappReportResponseDto> {

    return this.getWhatsappDeliveryReports$Response(params).pipe(
      map((r: StrictHttpResponse<WhatsappReportResponseDto>) => r.body as WhatsappReportResponseDto)
    );
  }

  
  /**
   * Path part for operation getSmsJobDeliveryStatus
   */
  static readonly GetSmsJobDeliveryStatusPath = '/api/v1/whatsapp-job/{jobId}/status';

  /**
   * Get SMS Job delivery status.
   *
   * The API to get SMS Job Status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmsJobDeliveryStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsJobDeliveryStatus$Response(params: {
    jobId: string;
  }
): Observable<StrictHttpResponse<Array<WhatsappJobStatusDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappDeliveryReportControllerService.GetSmsJobDeliveryStatusPath, 'get');
    if (params) {
      rb.path('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WhatsappJobStatusDto>>;
      })
    );
  }

  /**
   * Get SMS Job delivery status.
   *
   * The API to get SMS Job Status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSmsJobDeliveryStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsJobDeliveryStatus(params: {
    jobId: string;
  }
): Observable<Array<WhatsappJobStatusDto>> {

    return this.getSmsJobDeliveryStatus$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WhatsappJobStatusDto>>) => r.body as Array<WhatsappJobStatusDto>)
    );
  }

  
  /**
   * Path part for operation getSmsJobPhoneNumbersBasedOnStatus
   */
  static readonly GetSmsJobPhoneNumbersBasedOnStatusPath = '/api/v1/whatsapp-job/{jobId}/phone-number';

  /**
   * Get SMS Job numbers.
   *
   * The API to get  SMS Job List of mobile numbers based on Status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmsJobPhoneNumbersBasedOnStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsJobPhoneNumbersBasedOnStatus$Response(params: {
    jobId: string;
    status: string;
    page?: number;
    limit?: number;
  }
): Observable<StrictHttpResponse<Array<WhatsappJobPhoneNumbersDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappDeliveryReportControllerService.GetSmsJobPhoneNumbersBasedOnStatusPath, 'get');
    if (params) {
      rb.path('jobId', params.jobId, {});
      rb.query('status', params.status, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WhatsappJobPhoneNumbersDto>>;
      })
    );
  }

  /**
   * Get SMS Job numbers.
   *
   * The API to get  SMS Job List of mobile numbers based on Status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSmsJobPhoneNumbersBasedOnStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsJobPhoneNumbersBasedOnStatus(params: {
    jobId: string;
    status: string;
    page?: number;
    limit?: number;
  }
): Observable<Array<WhatsappJobPhoneNumbersDto>> {

    return this.getSmsJobPhoneNumbersBasedOnStatus$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WhatsappJobPhoneNumbersDto>>) => r.body as Array<WhatsappJobPhoneNumbersDto>)
    );
  }

   /**
   * Path part for operation submitWhatsappdeliveryRequest
   */
   static readonly SubmitWhatsappdeliveryRequestPath = '/api/v1/delivery-report/zip';

   /**
    * Place Whatsapp delivery report request.
    *
    * The API to place a new request to download high volume Whatsapp Delivery reports
    *
    * This method provides access to the full `HttpResponse`, allowing access to response headers.
    * To access only the response body, use `submitWhatsappdeliveryRequest()` instead.
    *
    * This method sends `application/json` and handles request body of type `application/json`.
    */
   submitWhatsappdeliveryRequest$Response(params?: {
     body?: WhatsappReportRequestDto
   }
 ): Observable<StrictHttpResponse<WhatsappReportResponseDto>> {
 
     const rb = new RequestBuilder(this.rootUrl, WhatsappDeliveryReportControllerService.SubmitWhatsappdeliveryRequestPath, 'post');
     if (params) {
       rb.body(params.body, 'application/json');
     }
 
     return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
     })).pipe(
       filter((r: any) => r instanceof HttpResponse),
       map((r: HttpResponse<any>) => {
         return r as StrictHttpResponse<WhatsappReportResponseDto>;
       })
     );
   }

  /**
   * Place Whatsapp delivery report request.
   *
   * The API to place a new request to download high volume Whatsapp Delivery reports
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `submitWhatsappdeliveryRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitWhatsappdeliveryRequest(params?: {
    body?: WhatsappReportRequestDto
  }
): Observable<WhatsappReportResponseDto> {

    return this.submitWhatsappdeliveryRequest$Response(params).pipe(
      map((r: StrictHttpResponse<WhatsappReportResponseDto>) => r.body as WhatsappReportResponseDto)
    );
  }

  /**
   * Path part for operation getAllWhatsappdeliveryReports
   */
  static readonly GetAllWhatsappdeliveryReportsPath = '/api/v1/delivery-report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllWhatsappdeliveryReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllWhatsappdeliveryReports$Response(params?: {
  }
): Observable<StrictHttpResponse<Array<WhatsappDeliveryReport>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappDeliveryReportControllerService.GetAllWhatsappdeliveryReportsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WhatsappDeliveryReport>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllWhatsappdeliveryReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllWhatsappdeliveryReports(params?: {
  }
): Observable<Array<WhatsappDeliveryReport>> {

    return this.getAllWhatsappdeliveryReports$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WhatsappDeliveryReport>>) => r.body as Array<WhatsappDeliveryReport>)
    );
  }

  
  /**
   * Path part for operation getZippedFile
   */
  static readonly GetZippedFilePath = '/api/v1/delivery-report/{requestId}';

  
  getZippedFile$Response(params: {
    requestId: string;
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappDeliveryReportControllerService.GetZippedFilePath, 'get');
    if (params) {
      rb.path('requestId', params.requestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

getZippedFile(params: {
  requestId: string;
}
): Observable<void> {

  return this.getZippedFile$Response(params).pipe(
    map((r: StrictHttpResponse<void>) => r.body as void)
  );
}

  /**
   * Path part for operation getZippedFile
   */
  static readonly DeleteZippedFilePath = '/api/v1/delivery-report/{requestId}';


  deleteFile$Response(params: {
    requestId: string;
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappDeliveryReportControllerService.DeleteZippedFilePath, 'delete');
    if (params) {
      rb.path('requestId', params.requestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

deleteFile(params: {
  requestId: string;
}
): Observable<void> {

  return this.deleteFile$Response(params).pipe(
    map((r: StrictHttpResponse<void>) => r.body as void)
  );
}


  /**
   * Path part for operation fetchChannelMappedTemplateAnalytics
   */
  static readonly FetchChannelMappedTemplateAnalyticsPath = '/api/v1/channel/{channelId}/templates-analytics';

 /**
  * This method provides access to the full `HttpResponse`, allowing access to response headers.
  * To access only the response body, use `fetchChannelMappedTemplateAnalytics()` instead.
  *
  * This method doesn't expect any request body.
  */
 fetchChannelMappedTemplateAnalytics$Response(params: {
    channelId: string;
  }
): Observable<StrictHttpResponse<Array<TemplateAnalyticsDto>>> {
   const rb = new RequestBuilder(this.rootUrl, WhatsappDeliveryReportControllerService.FetchChannelMappedTemplateAnalyticsPath, 'get');
   if (params) {
     rb.path('channelId', params.channelId, {});
   }

   return this.http.request(
     rb.build({ responseType: 'json',
     accept: 'application/json'})
   ).pipe(
     filter((r: any) => r instanceof HttpResponse),
     map((r: HttpResponse<any>) => {
       return r as StrictHttpResponse<Array<TemplateAnalyticsDto>>;
     })
   );
 }

 /**
  * This method provides access to only to the response body.
  * To access the full response (for headers, for example), `fetchChannelMappedTemplateAnalytics$Response()` instead.
  *
  * This method doesn't expect any request body.
  */
 fetchChannelMappedTemplateAnalytics(
   params: {
    channelId: string;
   }
 ): Observable<Array<TemplateAnalyticsDto>> {
   return this.fetchChannelMappedTemplateAnalytics$Response(params).pipe(
     map((r: StrictHttpResponse<Array<TemplateAnalyticsDto>>) => r.body as Array<TemplateAnalyticsDto>)
   );
 }

 /**
   * Path part for operation getLinkClickedCountByJobId
   */
 static readonly GetLinkClickedCountByJobIdPath = '/api/v1/get/link-clicked';

 /**
  * This method provides access to the full `HttpResponse`, allowing access to response headers.
  * To access only the response body, use `getLinkClickedCountByJobId()` instead.
  *
  * This method doesn't expect any request body.
  */
 getLinkClickedCountByJobId$Response(params: {
   jobId: string;
 }
): Observable<StrictHttpResponse<Array<WhatsAppLinkTrackingStatusCountDto>>> {

   const rb = new RequestBuilder(this.rootUrl, WhatsappDeliveryReportControllerService.GetLinkClickedCountByJobIdPath, 'get');
   if (params) {
     rb.query('jobId', params.jobId, {});
   }

   return this.http.request(rb.build({
     responseType: 'json',
     accept: 'application/json',
   })).pipe(
     filter((r: any) => r instanceof HttpResponse),
     map((r: HttpResponse<any>) => {
       return r as StrictHttpResponse<Array<WhatsAppLinkTrackingStatusCountDto>>;
     })
   );
 }

 /**
  * This method provides access to only to the response body.
  * To access the full response (for headers, for example), `getLinkClickedCountByJobId$Response()` instead.
  *
  * This method doesn't expect any request body.
  */
 getLinkClickedCountByJobId(params: {
   jobId: string;
 }
): Observable<Array<WhatsAppLinkTrackingStatusCountDto>> {

   return this.getLinkClickedCountByJobId$Response(params).pipe(
     map((r: StrictHttpResponse<Array<WhatsAppLinkTrackingStatusCountDto>>) => r.body as Array<WhatsAppLinkTrackingStatusCountDto>)
   );
 }


  /**
   * Path part for operation getLinkTrackDetailsByJobId
   */
  static readonly GetLinkTrackDetailsByJobIdPath = '/api/v1/getJobDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLinkTrackDetailsByJobId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLinkTrackDetailsByJobId$Response(params: {
    jobId: string;
    page?: number;
    limit?: number;
  }
): Observable<StrictHttpResponse<Array<WhatsAppLinkTrackingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappDeliveryReportControllerService.GetLinkTrackDetailsByJobIdPath, 'get');
    if (params) {
      rb.query('jobId', params.jobId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
     accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WhatsAppLinkTrackingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLinkTrackDetailsByJobId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLinkTrackDetailsByJobId(params: {
    jobId: string;
    page?: number;
    limit?: number;
  }
): Observable<Array<WhatsAppLinkTrackingDto>> {

    return this.getLinkTrackDetailsByJobId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WhatsAppLinkTrackingDto>>) => r.body as Array<WhatsAppLinkTrackingDto>)
    );
  }

  /**
   * Path part for operation downloadWhatsappOptOutReports
   */
  static readonly DownloadWhatsappOptOutReportsPath = '/api/v1/opt-out-report/excel';

  /**
   * Download Whatsapp optout report.
   *
   * The API to download Opt Out report in excel format for records
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadWhatsappOptOutReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadWhatsappOptOutReports$Response(params: {
    channelId: string;
    fromDate?: string;
    toDate?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappDeliveryReportControllerService.DownloadWhatsappOptOutReportsPath, 'post');
    if (params) {
      rb.query('channelId', params.channelId, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Download Whatsapp optout report.
   *
   * The API to download Opt Out report in excel format for records
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadWhatsappOptOutReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadWhatsappOptOutReports(params: {
    channelId: string;
    fromDate?: string;
    toDate?: string;
  }): Observable<void> {

    return this.downloadWhatsappOptOutReports$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation optOutCount
   */
  static readonly OptOutCountPath = '/api/v1/opt-out/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `optOutCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  optOutCount$Response(params: {
    channelId: string;
    fromDate?: string;
    toDate?: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappDeliveryReportControllerService.OptOutCountPath, 'get');
    if (params) {
      rb.query('channelId', params.channelId, {});
      rb.query('fromDate', params.fromDate, {});
      rb.query('toDate', params.toDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
     accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `optOutCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  optOutCount(params: {
    channelId: string;
    fromDate?: string;
    toDate?: string;
  }): Observable<string> {

    return this.optOutCount$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }



}
