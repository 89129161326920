<section class="overview-container">
    <section class="main">
      <div class="header-top">
        <span class="heading-label">
          <img class="page-icon-small" [src]="whatsappIcon" />
          <span>{{'ANALYTICS' | translate}}</span>
        </span>
      </div>
      <div></div>
    </section>
  </section>
      <div class="agent-spacing">
     <div class="flex-container first-section align-center">
        <div class="heading">{{'LIVE_MONITORING' |translate}}</div>
        <div class="note-label-item">
             <img class="refresh-icon" [src]="resetIcon"  (click)="refreshChatMetrics()" matTooltip=" {{'REFRESH_METRICS'|translate}}" matTooltipPosition="right" matTooltipClass="custom-tooltip">
            {{'LAST_UPDATE_ON'|translate}} - {{ todayDate | date:'hh:mm:ss a' }}
        </div>
        <div>
        <mat-form-field>
          <mat-label class="hidden-label">{{'ALL'|translate}}</mat-label>
          <mat-select   class="input-field" (selectionChange)="onChangeDepartment($event)"
          [placeholder]="'ALL' | translate"   [(value)]="selectedDepartment">
            <mat-option [value]="null" >{{ 'ALL' | translate }}</mat-option>
            <mat-option *ngFor="let val of departmentIdList" [value]="val.id">{{ val.name}}</mat-option>                        </mat-select>
        </mat-form-field>
      </div>
      <span class="dropdown-field"></span>
    </div>

    <div class="flex-container card-container">
      <div class="ongoing-chat-container">
        <div class="total-chat-card">
          <div>
            <img [src]="baseIconPath + openChatsCount.iconName" alt="ongoing-chat" class="ongoing-chat-img" />
            <div class="ongoing-chat-count">{{openChatsCount.count}}</div>
            <div class="ongoing-chat-title">
              <span>{{openChatsCount.title |translate}}</span>
              <span matTooltip="{{openChatsCount.tooltip}}" matTooltipPosition="right"  matTooltipClass="custom-tooltip" >
                <img [src]="infoIcon" alt="info" class="info-icon-chat" />
              </span>
            </div>
          </div>
        </div>
      </div>
      
      <div class="chat-card-container">
        <div class="chat-card" *ngFor="let card of chatCards">
          <div class="chats-card">
              <div class="flex-container">
                <div class="card-icon-container">
                <img class="card-icon" [src]="baseIconPath + card.iconName" [alt]="card.title + ' Icon'">
              </div>
              <div class="card-content">
                <div class="count">{{ card.count }}</div>
                   <div class="title-container">
                      <span class="message-heading">{{ card.title | translate }}</span>
                      <span matTooltip="{{ card.tooltip }}" matTooltipPosition="right" class="flex-container align-center" matTooltipClass="custom-tooltip">
                        <img [src]="infoIcon" alt="info" class="info-icon">
                      </span>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <p class="heading">{{'CHATS_CREATED_FOR_CURRENT_DAY' | translate}}</p>
    <div class="asset-chart-area">
      <div #currentDayCharts></div>
    </div>
        <p class="heading">{{'AGENT_SUMMARY' | translate}}</p>
        <div class="table-container" *ngIf="agentList.length">
            <mat-table [dataSource]="dataSource" class="table-data">
              <ng-container *ngFor="let column of columns">
                <ng-container [matColumnDef]="column">
                  <mat-header-cell *matHeaderCellDef>{{ column | appendSpace | titlecase }}</mat-header-cell>
                <mat-cell [ngClass]="{'user-rating-column': column === 'userRating'}"  *matCellDef="let agent" class="tooltip">
                  <ng-container *ngIf="column === 'userRating'; else defaultColumn">
                    <ng-container *ngIf="agent[column] === 0; else ratingStars">
                      <span>{{'NO_RATING' |translate}}</span>
                    </ng-container>
                    <ng-template #ratingStars>
                    <span *ngFor="let i of [].constructor(5); let idx = index">
                      <mat-icon class="rb-ic"
                    [fontIcon]="idx < agent[column] ? 'bosch-ic-wishlist-filled' : 'bosch-ic-wishlist'"
                        [ngClass]="{'filled-star': idx < agent[column]}"
                      ></mat-icon> 
                    </span>
                    </ng-template>
                  </ng-container>
                  <ng-template #defaultColumn>
                    <span title="{{ agent[column] }}" class="cell-value">
                      {{ agent[column] }}
                    </span>
                  </ng-template>
                  </mat-cell>
                </ng-container>
              </ng-container>
              <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
              <mat-row *matRowDef="let agent; columns: columns;"></mat-row>
            </mat-table>
          </div>
          <mat-paginator *ngIf="dataSource && agentList.length > 0" [pageSizeOptions]="pageSizeOptions" [length]="totalAgents" [pageSize]="pageSize"
          showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>
          <div *ngIf="agentList.length === 0" class="no-agent">
            <h5>{{'NO_AGENT_DATA_FOR_THIS_AGENT_LIST' |translate}}</h5>
          </div>
      </div>
     