import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { AreaChartService } from 'src/app/providers/area-spline-chart-service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { PlatformAdminUtilityControllerService } from 'src/app/services/Platform/services';
import { AgentDto, AgentRequestDto, AgentResponseDto } from 'src/app/services/Whatsapp-agent/models';
import {
  AgentControllerService,
  DepartmentControllerService,
} from 'src/app/services/Whatsapp-agent/services';
import {
  BASE_ICON_PATH,
  INFO_ICON_IMAGE,
  REFRESH_WITH_TEXT,
  RESET_ICON,
  WHATSAPP_ICON,
} from 'src/app/shared/constants/strings';

@Component({
  selector: 'app-agent-analytic',
  templateUrl: './agent-analytic.component.html',
  styleUrls: ['./agent-analytic.component.scss'],
})
export class AgentAnalyticComponent implements OnInit {
  whatsappIcon: string;
  agentId: string;
  agentList: any[] = [];
  public dataSource;
  public columns: any[];
  public ignoreList = [
    'userId',
    'channelNumber',
    'departmentId',
    'workDuration',
    'enabled',
    'deleted',
    'closedChatsCount',
    'activeChatsCount',
  ];
  wabaNumber: any;
  departmentIdList: { id: string; name: string }[];
  refresh: any;
  resetIcon: string;
  todayDate = new Date();
  messageChats: string;
  infoIcon: string;
  chatCards: any;
  baseIconPath: string;
  @ViewChild('currentDayCharts', { static: true })
  public currentDayChartElement: ElementRef;
  chart;
  openChatsCount = {
    count: 0,
    title: 'openChatsCount',
    iconName: 'message_chats.svg',
    tooltip: 'Total no. of conversations that are currently active',
  };
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageSize: number = this.pageSizeOptions[0];
  pageIndex: number = 0;
  totalAgents: number;
  departmentId: any;
  selectedDepartment: any = null; 

  constructor(
    private agentControllerService: AgentControllerService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private responseHandlerService: ResponseHandlerService,
    private departmentControllerService: DepartmentControllerService,
    private httpService: HttpClient,
    private areaChartService: AreaChartService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.whatsappIcon = WHATSAPP_ICON;
    this.refresh = REFRESH_WITH_TEXT;
    this.resetIcon = RESET_ICON;
    this.infoIcon = INFO_ICON_IMAGE;
    this.baseIconPath = BASE_ICON_PATH;
    this.httpService
      .get('./assets/dashboard/chat-cards-config.json')
      .subscribe((data) => {
        this.chatCards = data;
      });
    this.getWabaNumber();
  }

  getWabaNumber() {
    this.platformAdminUtilityControllerService
      .getUserAccountDetails()
      .subscribe(
        (resp) => {
          if (resp) {
            this.wabaNumber = resp['wabaNumber'];
            this.getAgentDetailsList(this.wabaNumber,this.pageIndex,this.pageSize);
            this.getDepartmentsList(this.wabaNumber);
            this.getChatCount(this.wabaNumber);
            this.getHourlyChatCounts(this.wabaNumber);
          }
        },
        (err) => {
          this.handleError(err);
        }
      );
  }

  getAgentDetailsList(channelNumber, page, limit, departmentId?) {
    const body: AgentRequestDto = { channelNumber };
    const para = {
      body,
      departmentId: departmentId,
      page: page, limit: limit 
    };
    this.agentControllerService.getAgentsByDepartmentAndChannel(para).subscribe((resp: AgentResponseDto) => {
      const agents = resp.agentList; 
      this.totalAgents = resp.totalAgents;
  
      if (agents && agents.length > 0) {
        this.agentList = agents.map((agent) => ({
          ...agent,
          closedChats: agent.closedChatsCount,
          activeChats: agent.activeChatsCount,
          totalChats: agent.closedChatsCount + agent.activeChatsCount,
        }));
  
          this.dataSource = new MatTableDataSource<AgentDto>(this.agentList);
          this.columns = this.filterColumns(Object.keys(this.agentList[0]));
      } else {
        this.agentList = [];
        this.dataSource = new MatTableDataSource<AgentDto>(this.agentList);
        this.columns = [];
        }
      });
  }

  getDepartmentsList(channelNumber) {
    const body: AgentRequestDto = { channelNumber };
    const para = {
      body,
    };

    this.departmentControllerService
      .getDepartmentsByChannelNumber(para)
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            const response = resp.map((item) => ({
              id: item.id,
              name: item.departmentName,
            }));
            this.departmentIdList = response;
          } else {
            this.departmentIdList = [];
          }
        },
        (err) => {
          this.handleError(err);
        }
      );
  }

  getChatCount(channelNumber, departmentId?) {
    const body: AgentRequestDto = { channelNumber };
    const params = {
      body,
      departmentId: departmentId,
    };
    this.agentControllerService.getChatCounts(params).subscribe(
      (resp) => {
        if (resp) {
          this.openChatsCount.count = resp.openChatsCount || 0;
          this.updateChatCounts(resp);
        }
      },
      (err) => {
        this.handleError(err);
      }
    );
  }

  updateChatCounts(resp: any) {
    this.chatCards.forEach((card) => {
      if (resp[card.title] !== undefined) {
        card.count = resp[card.title];
      }
    });
    this.chatCards = this.chatCards.filter(
      (card) => card.title !== 'openChatsCount'
    );
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  handleError(err) {
    this.departmentIdList = [];
    this.responseHandlerService.returnToastMessage('error', err.error.message);
  }

  onChangeDepartment(event: any) {
    this.updateLastUpdateTime();
    this.pageIndex = 0; 
    this.dataSource = null; 
    if (event.value === null) {
      this.getChatCount(this.wabaNumber);
      this.getAgentDetailsList(this.wabaNumber,this.pageIndex,this.pageSize);
      this.getHourlyChatCounts(this.wabaNumber);
      this.departmentId = null;
    } else {
      const departmentId =
        event.value === 'Default' ? this.wabaNumber : event.value;
      this.departmentId = departmentId;
      this.getAgentDetailsList(this.wabaNumber,this.pageIndex,this.pageSize,departmentId);
      this.getChatCount(this.wabaNumber, departmentId);
      this.getHourlyChatCounts(this.wabaNumber, departmentId);
    }
  }

  /**
   * @ngdoc method
   * @name AgentAnalyticComponent#getHourlyChatCounts
   *
   * @methodOf AgentAnalyticComponent
   *
   * @description
   * Retrieves the hourly chat counts based on the specified channel number and optional department ID.
   * It makes an API call to fetch chat counts and processes the response to create a Highcharts areaspline chart.
   * If a chart already exists, it will be destroyed before creating a new one with the fetched data.
   *
   * @param {string} channelNumber - The channel number to filter chat counts.
   * @param {string} [departmentId] - Optional department ID to further filter the chat counts.
   *
   * @returns {void} null - This method does not return any value.
   */
  public getHourlyChatCounts(channelNumber, departmentId?) {
    if (this.chart) {
      this.areaChartService.destroyChart(this.chart);
    }
    const body: AgentRequestDto = { channelNumber };
    const params = {
      body,
      departmentId: departmentId,
    };
    this.agentControllerService
      .getHourlyChatCounts(params)
      .subscribe((resp) => {
        const chartData = this.mapResponseToChart(resp);
        this.chart = this.areaChartService.createChart(
          this.currentDayChartElement.nativeElement,
          chartData
        );
      });
  }

  /**
   * @ngdoc method
   * @name AgentAnalyticComponent#mapResponseToChart
   *
   * @methodOf AgentAnalyticComponent
   *
   * @description
   * Maps the API response to a format compatible with the Highcharts areaspline chart.
   * This method processes the response by extracting the necessary data for the x-axis
   * (hourOfDay) and y-axis (assignedChats, waitingForAssignmentChats, newChats).
   * It returns an array of formatted chart series data for Highcharts.
   *
   * @param {Array<Object>} response - The API response containing hourly chat data.
   * Each object in the array should contain the following fields: 'hourOfDay', 'assignedChats',
   * 'waitingForAssignmentChats', and 'newChats'.
   *
   * @returns {Array<Object>} chartData - An array of chart series objects, where each object contains:
   *   - {string} name - The translated name for the series.
   *   - {Array<number>} values - The data points for the y-axis (assignedChats, waitingForAssignmentChats, or newChats).
   *   - {string} color - The color associated with the series.
   *   - {Array<string>} dates - The x-axis categories (hourOfDay).
   */
  mapResponseToChart(response: any[]): Array<any> {
    const chartData = {
      dates: [] as string[],
      assignedChats: [] as number[],
      waitingChats: [] as number[],
      newChats: [] as number[],
    };
    response.forEach((item) => {
      chartData.dates.push(item.hourOfDay);
      chartData.assignedChats.push(item.assignedChats);
      chartData.waitingChats.push(item.waitingForAssignmentChats);
      chartData.newChats.push(item.newChats);
    });
    return [
      {
        name: this.getTranslatedValues('ASSIGNED_CHATS'),
        values: chartData.assignedChats,
        color: '#2196F3',
        dates: chartData.dates,
      },
      {
        name: this.getTranslatedValues('WAITING_FOR_ASSIGNED'),
        values: chartData.waitingChats,
        color: '#FF8DA5',
        dates: chartData.dates,
      },
      {
        name: this.getTranslatedValues('NEW_CHATS'),
        values: chartData.newChats,
        color: '#B18DFF',
        dates: chartData.dates,
      },
    ];
  }

  public getTranslatedValues(key: string) {
    let translatedValue;
    this.translate.get(key).subscribe((translatedKey: string) => {
      translatedValue = translatedKey;
    });
    return translatedValue;
  }

   /**
   * @ngdoc method
   * @name AgentAnalyticComponent#pageEvent
   *
   * @methodOf
   * sim.controller:AgentAnalyticComponent
   *
   * @description
   * Description: To fetch the next list of sims.
   *
   * @param {type} event
   * @return {type} null
   */
   public pageEvent(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAgentDetailsList(this.wabaNumber,this.pageIndex, this.pageSize,this.departmentId);
  }

  refreshChatMetrics() {
    this.updateLastUpdateTime();
    this.selectedDepartment = null;
    this.getAgentDetailsList(this.wabaNumber,this.pageIndex,this.pageSize);
    this.getChatCount(this.wabaNumber);
    this.getHourlyChatCounts(this.wabaNumber);
  }

  private updateLastUpdateTime() {
    this.todayDate = new Date(); 
  }

}
