import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { BciCoreModule } from '@bci-web-core/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WhatsappRoutingModule } from './whatsapp-routing.module';
import { CustomerOnboardingComponent } from './customer-onboarding/customer-onboarding.component';
import { ProfileConnectionComponent } from './profile-connection/profile-connection.component';
import { CreateWhatsappTemplateComponent } from './create-whatsapp-template/create-whatsapp-template.component';
import { ManageWhatsappTemplateComponent } from './manage-whatsapp-template/manage-whatsapp-template.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { WhatsappStatusComponent } from './whatsapp-status/whatsapp-status.component';
import { DataAnalyticsComponent } from './data-analytics/data-analytics.component';
import { BotConfigurationComponent } from './bot-configuration/bot-configuration.component';
import { CampaignManagementComponent } from './campaign-management/campaign-management.component';
import { CampaignManagementDetailsComponent } from './campaign-management-details/campaign-management-details.component';
import { WhatsappAnalyticsComponent } from './whatsapp-analytics/whatsapp-analytics.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ClickTrackingAnalysisComponent } from './click-tracking-analysis/click-tracking-analysis.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WebChatComponent } from './web-chat/web-chat.component';
import { AgentConfigComponent } from './agent-config/agent-config.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DepartmentConfigComponent } from './department-config/department-config.component';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AgentAnalyticComponent } from './agent-analytic/agent-analytic.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { AgentListComponent } from './agent-list/agent-list.component';


@NgModule({
  declarations: [CustomerOnboardingComponent, ProfileConnectionComponent, 
    CreateWhatsappTemplateComponent, ManageWhatsappTemplateComponent, 
    WhatsappStatusComponent, DataAnalyticsComponent,
    BotConfigurationComponent, CampaignManagementComponent, 
    CampaignManagementDetailsComponent,WhatsappAnalyticsComponent, 
    ClickTrackingAnalysisComponent, WebChatComponent, AgentConfigComponent, DepartmentConfigComponent,AgentAnalyticComponent, AgentListComponent],
  imports: [
    CommonModule,
     MatTabsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BciCoreModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatCardModule,
    WhatsappRoutingModule,
    MatCheckboxModule,
    PickerModule,
    InfiniteScrollModule,
    MatChipsModule,
    MatAutocompleteModule,
    NgxLinkifyjsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatRadioModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WhatsappModule { }
