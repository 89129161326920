import { Component, OnInit, ViewChildren, QueryList, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AccountControllerService,
  TenantControllerService,
  BusinessProfileControllerService,
  SmsTemplateControllerService,
  SmsChannelControllerService,
} from 'src/app/services/Platform/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { ActivatedRoute } from '@angular/router';
import { SMS_ICON } from 'src/app/shared/constants/strings';
import { BciLoaderService } from '@bci-web-core/core';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-sms-configuration',
  templateUrl: './sms-configuration.component.html',
  styleUrls: ['./sms-configuration.component.scss'],
})
export class SmsConfigurationComponent implements OnInit {
  public configurationsList: Array<String> = [];
  public jsonFileLoaded: string;
  public dropDownValues: any = {};
  public showSpinner :any;
  public data: any[];
  public columns: any[];
  public tabName: string;
  public smsStatusData: any;
  public accountId: string;
  public tenantId: string;
  public solutionTypeId;
  public profileId: string;
  public senderId: string;
  public dropdownList: [];
  public submitted = false;
  public headerData: any = {};
  public permissionList: Array<String> = [];
  public ignoreList = ['profileName', 'jobId', 'smsTemplateId', 'hideEditIcon','headerId'];
  public statusData;
  public statusColumns: any[];
  public currentDate = new Date();
  public smsIcon;
  hideSubmitButton: boolean = false;
  @ViewChildren(DynamicFormComponent)
  dynamicForms: QueryList<DynamicFormComponent>;
  showDownload = false;
  profileName: string;
   @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  constructor(
    private httpsService: HttpClient,
    private accountControllerService: AccountControllerService,
    private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private responseHandlerService: ResponseHandlerService,
    private smsTempateControllerService: SmsTemplateControllerService,
    private smsChannelControllerService: SmsChannelControllerService,
   
    private route: ActivatedRoute,
    private loaderService: BciLoaderService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    if (!this.checkPermission('S_TMPL')) {
      this.waitForDynamicForm().then((dynamicForm) => {
        this.dynamicForms['last']['formBase'].forEach((control) => {
          if (control.name === 'templateArray') {
            control['hidden'] = true;
          }
        });
        this.dynamicForms['first']['formBase'].forEach((control) => {
          if (control.name === 'smsHeader') {
            control['hidden'] = true;
          }
        });
      });
      this.hideSubmitButton = true;
    }
    this.httpsService
      .get('./assets/form-fields/sms-tabs.json')
      .subscribe((data) => {
        this.configurationsList = data as string[];
        this.dropdownList = this.configurationsList['dropdownList'];
        this.configurationsList = this.configurationsList['fields'];
      });

    this.getAccount();
    this.getSmsChannels();
    this.smsIcon = SMS_ICON;
  }

  public waitForDynamicForm() {
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (this.dynamicForms['last']['formBase']) {
          clearInterval(intervalId);
          resolve(this.dynamicForms['last']['formBase']);
        }
      }, 100);
    });
  }

  public updateDropdownValues($event,tabTitle?) {
    if ($event.value !== undefined) {
      if ($event.child !== undefined) {
        if ($event.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
        }
        if ($event.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if ($event.child === 'smsConfig') {
          this.profileId = $event.value;
          if (tabTitle === 'sms-header') {
            this.dynamicForms.first.dataSourceDeletedRows = [];
            this.getSmsHeader($event.value);
          } else {
            this.generateExcelFilename($event.value);
            this.showDownload = false;
            this.getSmsTemplate($event.value);
            if (this.checkPermission('S_TMPL')) {
            this.getSmsHeader($event.value);
            }
          }
        }
        }
    }
  }

  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public submitSmsConfiguration(data: any,title) {
    if (title === 'sms-header') {
      this.showSpinner = this.loaderService.showProgressBar();
      const smsHeaders: any[] = [];
    const deletedRows = this.dynamicForms.first.dataSourceDeletedRows;
    if (deletedRows.length) {
      data['table'] = data['table']? data['table'].concat(deletedRows): deletedRows;
    }
      if (data['table'] !== undefined) {
        data['table'].forEach((row) => {
            smsHeaders.push({
                  headerId:row['headerId'],
                  entityId: row['entityId'],
                  senderId: row['senderId'],
                  action: !row['headerId']? 'CREATED': row['action']? row['action']: null,
                }
            );
        });
        if (smsHeaders.length) {
      this.businessProfileControllerService
        .saveSmsHeadder({
          businessProfileId: this.profileId,
          body:  smsHeaders,
        })
        .subscribe(
          (resp) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.submitted = false;
            this.dynamicForms.first.dataSourceDeletedRows = [];
            this.responseHandlerService.returnToastMessage(
              'success',
              'SMS_HEADER_CONFIGURATION_SUCCESS'
            );
            this.getSmsHeader(this.profileId);
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
      }
    }
    }
    if (title === 'sms-template') {
      this.showSpinner = this.loaderService.showProgressBar();
      const templateInfos: any[] = [];
      setTimeout(() => {
      if (data['table'] !== undefined) {
        data['table'].forEach((row) => {
          if (row['action'] === 'UPDATED') {
            templateInfos.push({
              channelId: row['channel'],
              templates: [
                {
                  headerId: row['senderId'],
                  traiSmsTemplateId: row['traiTemplateId'],
                  smsTemplate: row['smsTemplateContent'],
                  templateName: row['smsTemplateName'],
                  language: row['language'],
                },
              ],
            });
          }
        });
        if (templateInfos.length) {
          this.smsTempateControllerService
            .saveSmsTemplate({
              businessProfileId: this.profileId,
              body: templateInfos,
            })
            .subscribe(
              (resp) => {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.submitted = false;
                this.responseHandlerService.returnToastMessage(
                  'success',
                  'SMS_TEMPLATE_CONFIGURATION_SUCCESS'
                );
                this.getSmsTemplate(this.profileId);
              },
              (err) => {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage(
                  'error',
                  err.error.message
                );
              }
            );
        }
      }
      }, 3000);
      if (this.dynamicForms.last.dataSourceDeletedRows !== undefined) {
        this.dynamicForms.last.dataSourceDeletedRows.forEach((row) => {
          this.smsTempateControllerService
            .deleteSmsTemplate({
              businessProfileId: this.profileId,
              smsTemplateId: row['smsTemplateId'],
            })
            .subscribe(
              (resp) => {
                this.loaderService.hideProgressBar(this.showSpinner);
                if (!templateInfos.length) {
                  this.responseHandlerService.returnToastMessage(
                    'success',
                    'SMS_TEMPLATE_CONFIGURATION_SUCCESS'
                  );
                  this.getSmsTemplate(this.profileId);
                }
              },
              (err) => {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage(
                  'error',
                  err.error.message
                );
              }
            );
        });
      }
      this.dynamicForms.last.dataSourceDeletedRows = [];
    }
  }

  public resetForms(field) {
    if (this.dynamicForms.first) {
      this.dynamicForms.first.createForm.reset();
      this.dynamicForms.first.dataSource = [];
    }
    if (this.dynamicForms.last) {
      this.dynamicForms.last.createForm.reset();
      this.dynamicForms.last.dataSource = [];
      this.dynamicForms.last.dataSourceDeletedRows = [];
    }
    if (field === 'tenant' || field === 'businessProfile') {
      this.dropDownValues['businessProfile'] = [];
    } else if (field === 'resetButtonClicked') {
      this.dropDownValues['tenant'] = [];
      this.dropDownValues['businessProfile'] = [];
    }
    this.setLanguage();
    this.showDownload = false;
  }

  public getSmsHeader(data: any) {
    this.businessProfileControllerService
      .getSmsHeaderByBusinessProfile({ businessProfileId: data })
      .subscribe(
        (resp) => {
          const response = resp.map(item => ({
            'id': item.headerId,
            'name': item.senderId
          }));
          this.dropDownValues['senderId'] = response;
          const dataTable = [];
          const dataTableWithIds = [];
          resp.forEach((row) => {
            dataTable.push({
              headerId:row['headerId'],
              senderId: row['senderId'],
              entityId: row['entityId'],
            });
            dataTableWithIds.push({
              headerId:row['headerId'],
              senderId: row['senderId'],
              entityId: row['entityId'],
            });
          });
          this.dynamicForms.first.dataSource = dataTable;
          this.dynamicForms.first.dataSourceWithIds = dataTableWithIds;
          this.dynamicForms.first.columns = this.filterColumns(
            Object.keys(dataTable[0])
          );
          if (this.checkPermission('S_TMPL')) {
            this.dynamicForms.first.columns.push('action');
          } else {
            this.dropDownValues['senderId'] = [];
            this.loaderService.hideProgressBar(this.showSpinner);
          }
        },
        (err) => {
          this.dropDownValues['senderId'] = [];
          if (!this.checkPermission('S_TMPL')) {
            this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
        }
      );
  }

  public getSmsChannels() {
    this.smsChannelControllerService.getAllSmsChanels({}).subscribe(
      (resp) => {
        this.dropDownValues['channel'] = resp;
      },
      (err) => {
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getSmsTemplate(data: any) {
    if (!this.checkPermission('S_TMPL')) {
      this.showSpinner = this.loaderService.showProgressBar();
    }
    this.smsTempateControllerService
      .getSmsTemplates({ businessProfileId: data })
      .subscribe(
        (resp) => {
          if (resp) {
            this.showDownload = true;
            const dataTable = [];
            const dataTableWithIds = [];
            resp.forEach((row) => {
              dataTable.push({
                senderId: row['senderId'],
                traiTemplateId: row['traiTemplateId'],
                smsTemplateId: row['smsTemplateId'],
                smsTemplateName: row['smsTemplateName'],
                language: row['language'],
                channel: row['channelTypeName'],
                smsTemplateContent: row['smsTemplate'],
                hideEditIcon: true,
              });
              dataTableWithIds.push({
                senderId: row['senderId'],
                traiTemplateId: row['traiTemplateId'],
                smsTemplateId: row['smsTemplateId'],
                smsTemplateName: row['smsTemplateName'],
                language: row['language'],
                channel: {
                  id: row['channelTypeId'],
                  name: row['channelTypeName'],
                },
                smsTemplateContent: row['smsTemplate'],
              });
            });
            this.dynamicForms.last.dataSource = dataTable;
            this.dynamicForms.last.dataSourceWithIds = dataTableWithIds;
            this.dynamicForms.last.columns = this.filterColumns(
              Object.keys(dataTable[0])
            );
            if (this.checkPermission('S_TMPL')) {
              this.dynamicForms.last.columns.push('action');
            } else {
              this.loaderService.hideProgressBar(this.showSpinner);
            }
          }
        },
        (err) => {
          this.showDownload = false;
          if (!this.checkPermission('S_TMPL')) {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        }
      );
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  setLanguage(){
    this.dynamicForms.last.createForm?.controls['templateArray']['controls'].forEach(control =>{
      control['controls']['language'].setValue('En');
    });
  }

  public hideEditIcon(event) {
    if (event === 'Button clicked') {
      setTimeout(() => {
        this.dynamicForms.last.dataSource.forEach((data) => {
          data['hideEditIcon'] = true;
        });
      });
    }
  }

  downloadTemplate(){
    let timestamp: Date;
    const params = {
      businessProfileId: this.profileId
    };
    this.smsTempateControllerService
      .exportSmsTemplates(params )
      .subscribe(
        (resp) => {
         timestamp = new Date();
         const fileName = `${this.profileName}_Templates_${this.datePipe.transform(timestamp, 'yyyy-MM-dd_HH.mm.ss')}.csv`;
         this.downloadFile(resp,fileName);
        },
        (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error',err.error.message);
        }
      );

  }

  public downloadFile(data, fileName) {
    const FileSaver = require('file-saver');
    const blob = new Blob(['\ufeff'+data],{ type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, fileName);
  }

  public generateExcelFilename(profileId) {
    this.dropDownValues['businessProfile'].forEach( row => {
      if (row['id'] === profileId) {
        this.profileName = row['name'];
      }
    });
  }

  public onTabChange() {
    if(this.dynamicForms) {
      this.dynamicForms.last.dataSource = [];
      this.dynamicForms.first?.createForm?.reset();
      this.dynamicForms.last?.createForm?.reset();
      this.dynamicForms.last.columns = [];
      this.dynamicForms.first.dataSource = [];
      this.dynamicForms.first.columns = [];
    }
    this.dropDownValues['account'] = [];
    this.dropDownValues['tenant'] = [];
    this.dropDownValues['businessProfile'] = [];
    this.dropDownValues['senderId'] = [];
    this.dynamicForm.createForm?.reset();
    this.accountId = null;
    this.tenantId = null;
    this.profileId = null;
    this.getAccount();
    this.setLanguage();
  }

}
