<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="smsIcon" />
        <span>{{ 'SMS_CONFIGURATIONS' | translate }}</span>
      </span>
    </div>

    <mat-tab-group class="demo-tab-group stretched-tabs" mat-stretch-tabs (selectedIndexChange) = "onTabChange()">
      <div *ngFor="let tab of configurationsList">
        <mat-tab *ngIf="checkPermission(tab['permission'])">
          <ng-template mat-tab-label>
            <div>{{ tab["title"] | translate }}</div>
          </ng-template>
          <section class="tab-body">
            <div *ngIf="tab['name'] !== 'sms-status'" class="create-form-spacing">
              <div *ngIf="tab['name'] == 'sms-header'">
                <div class="clear-float"></div>
                <app-dynamic-form [dropDownValues]="dropDownValues" [jsonFile]="tab['json']" [hideSubmitButton]="hideSubmitButton" 
                  [gridLayout]="'grid-container-sms-header'"   (valueSelected)="updateDropdownValues($event,tab['name'])"
                  (formEmitter)="submitSmsConfiguration($event,tab['name'])" (resetForm)="resetForms($event,tab['name'])" (buttonClickEvent)="hideEditIcon($event)">
                </app-dynamic-form>
                </div>
                <div *ngIf="tab['name'] == 'sms-template'">
                  <app-dynamic-form [dropDownValues]="dropDownValues" [jsonFile]="tab['json']" [hideSubmitButton]="hideSubmitButton" 
                  [gridLayout]="'grid-container-sms-template'"   (valueSelected)="updateDropdownValues($event,tab['name'])"
                    (formEmitter)="submitSmsConfiguration($event,tab['name'])" (resetForm)="resetForms($event,tab['name'])" (buttonClickEvent)="hideEditIcon($event)">
                  </app-dynamic-form>
                  <button  *ngIf="showDownload" (click)="downloadTemplate()" mat-raised-button class="button save-continue-button button-submit"  [title]="'EXPORT' | translate" > {{'EXPORT' | translate}}</button>
              </div>
            </div>
          </section>
        </mat-tab>
      </div>
    </mat-tab-group>
  </section>
</section>
