/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ChatMessagesResponseDto } from '../models/chat-messages-response-dto';
import { ChatRoomDetailsResponseDto } from '../models/chat-room-details-response-dto';
import { AgentDto } from '../models/agent-dto';
import { MessageDto } from '../models/message-dto';
import { AgentRequestDto } from '../models/agent-request-dto';
import { ChatCountsDto } from '../models/chat-counts-dto';
import { HourlyChatsCountDto } from '../models/hourly-chats-count-dto';
import { AgentResponseDto } from '../models/agent-response-dto';

@Injectable({
  providedIn: 'root',
})
export class AgentControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

 /**
   * Path part for operation createAgent
   */
 static readonly CreateAgentPath = '/api/v1/create-agent';

 /**
  * Create Agent.
  *
  * API to create an agent for specified Channel
  *
  * This method provides access to the full `HttpResponse`, allowing access to response headers.
  * To access only the response body, use `createAgent()` instead.
  *
  * This method sends `application/json` and handles request body of type `application/json`.
  */
 createAgent$Response(params: {
   body: AgentDto
 }
): Observable<StrictHttpResponse<MessageDto>> {

   const rb = new RequestBuilder(this.rootUrl, AgentControllerService.CreateAgentPath, 'post');
   if (params) {
     rb.body(params.body, 'application/json');
   }

   return this.http.request(rb.build({
     responseType: 'json',
     accept: 'application/json',
     
   })).pipe(
     filter((r: any) => r instanceof HttpResponse),
     map((r: HttpResponse<any>) => {
       return r as StrictHttpResponse<MessageDto>;
     })
   );
 }

 /**
  * Create Agent.
  *
  * API to create an agent for specified Channel
  *
  * This method provides access to only to the response body.
  * To access the full response (for headers, for example), `createAgent$Response()` instead.
  *
  * This method sends `application/json` and handles request body of type `application/json`.
  */
 createAgent(params: {
   body: AgentDto
 }
): Observable<MessageDto> {

   return this.createAgent$Response(params).pipe(
     map((r: StrictHttpResponse<MessageDto>) => r.body as MessageDto)
   );
 }

  /**
   * Path part for operation getChatHistory
   */
  static readonly GetChatHistoryPath = '/api/v1/chat-messages/{chatRoomId}';

  /**
   * Get All Chat Messages.
   *
   * API to get all chat messages based on chat Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChatHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChatHistory$Response(params: {
    chatRoomId: string;
    page?: number;
    limit?: number;
  }
): Observable<StrictHttpResponse<ChatMessagesResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetChatHistoryPath, 'get');
    if (params) {
      rb.path('chatRoomId', params.chatRoomId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChatMessagesResponseDto>;
      })
    );
  }

  /**
   * Get All Chat Messages.
   *
   * API to get all chat messages based on chat Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChatHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChatHistory(params: {
    chatRoomId: string;
    page?: number;
    limit?: number;
  }
): Observable<ChatMessagesResponseDto> {

    return this.getChatHistory$Response(params).pipe(
      map((r: StrictHttpResponse<ChatMessagesResponseDto>) => r.body as ChatMessagesResponseDto)
    );
  }

  /**
   * Path part for operation getAgentChatRooms
   */
  static readonly GetAgentChatRoomsPath = '/api/v1/agent-chatrooms/{agentId}';

  /**
   * Get Agent Chat Rooms.
   *
   * API to get all agent related chat rooms
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentChatRooms1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentChatRooms$Response(params: {
    agentId: string;
    active?: string;
    page?: number;
    limit?: number;
  }
): Observable<StrictHttpResponse<ChatRoomDetailsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetAgentChatRoomsPath, 'get');
    if (params) {
      rb.path('agentId', params.agentId, {});
      rb.query('active', params.active, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChatRoomDetailsResponseDto>;
      })
    );
  }

  /**
   * Get Agent Chat Rooms.
   *
   * API to get all agent related chat rooms
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAgentChatRooms1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentChatRooms(params: {
    agentId: string;
    active?: string;
    page?: number;
    limit?: number;
  }
): Observable<ChatRoomDetailsResponseDto> {

    return this.getAgentChatRooms$Response(params).pipe(
      map((r: StrictHttpResponse<ChatRoomDetailsResponseDto>) => r.body as ChatRoomDetailsResponseDto)
    );
  }


  /**
   * Path part for operation updateCustomerFeedBack
   */
  static readonly UpdateCustomerFeedBackPath = '/api/v1/user/feedback';

  /**
   * Update User Feedback.
   *
   * API to update user feedback for Agent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerFeedBack()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerFeedBack$Response(params: {
    secretKey: string;
    userRating: number;
    body: AgentRequestDto
  }
): Observable<StrictHttpResponse<MessageDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgentControllerService.UpdateCustomerFeedBackPath, 'post');
    if (params) {
      rb.header('apiKey', params.secretKey, {});
      rb.query('userRating', params.userRating, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
     responseType: 'json',
     accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageDto>;
      })
    );
  }

  /**
   * Update User Feedback.
   *
   * API to update user feedback for Agent
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCustomerFeedBack$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerFeedBack(params: {
    secretKey: string;
    userRating: number;
    body: AgentRequestDto
  }
): Observable<MessageDto> {

    return this.updateCustomerFeedBack$Response(params).pipe(
      map((r: StrictHttpResponse<MessageDto>) => r.body as MessageDto)
    );
  }

  /**
   * Path part for operation transferChatToAgent
   */
  static readonly TransferChatToAgentPath = '/api/v1/transfer-chat/agent';

  /**
   * Transfer chat to Agent.
   *
   * API to transfer chat from Bot to Agent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transferChatToAgent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transferChatToAgent$Response(params: {
    secretKey: string;
    assignmentRule?: string;
    body: AgentRequestDto
  }
): Observable<StrictHttpResponse<MessageDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgentControllerService.TransferChatToAgentPath, 'post');
    if (params) {
      rb.header('apiKey', params.secretKey, {});
      rb.query('assignmentRule', params.assignmentRule, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
     responseType: 'json',
     accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageDto>;
      })
    );
  }

  /**
   * Transfer chat to Agent.
   *
   * API to transfer chat from Bot to Agent
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transferChatToAgent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transferChatToAgent(params: {
    secretKey: string;
    assignmentRule?: string;
    
    body: AgentRequestDto
  }
): Observable<MessageDto> {

    return this.transferChatToAgent$Response(params).pipe(
      map((r: StrictHttpResponse<MessageDto>) => r.body as MessageDto)
    );
  }

 



  /**
   * Path part for operation closeWhatsappSession
   */
  static readonly CloseWhatsappSessionPath = '/api/v1/close-whatsapp-session';

  /**
   * Close Whatsapp Session.
   *
   * API to close the whatsapp session
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeWhatsappSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  closeWhatsappSession$Response(params?: {
    
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AgentControllerService.CloseWhatsappSessionPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Close Whatsapp Session.
   *
   * API to close the whatsapp session
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `closeWhatsappSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  closeWhatsappSession(params?: {
    
  }
): Observable<void> {

    return this.closeWhatsappSession$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }


  /**
   * Path part for operation transferChat
   */
  static readonly TransferChatPath = '/api/v1/chats/{chatRoomId}/transfer/agent/{agentId}';

  /**
   * Transfer chat to Another Agent.
   *
   * API to transfer chat from one agent to another of same channel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transferChat()` instead.
   *
   * This method doesn't expect any request body.
   */
  transferChat$Response(params: {
    chatRoomId: string;
    agentId: string;
    fromAgentName: string;
    toAgentName: string;
  }): Observable<StrictHttpResponse<MessageDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgentControllerService.TransferChatPath, 'post');
    if (params) {
      rb.path('chatRoomId', params.chatRoomId, {});
      rb.path('agentId', params.agentId, {});
      rb.query('fromAgentName', params.fromAgentName, {});
      rb.query('toAgentName', params.toAgentName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageDto>;
      })
    );
  }

  /**
   * Transfer chat to Another Agent.
   *
   * API to transfer chat from one agent to another of same channel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transferChat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transferChat(params: {
    chatRoomId: string;
    agentId: string;
    fromAgentName: string;
    toAgentName: string;
  }): Observable<MessageDto> {

    return this.transferChat$Response(params).pipe(
      map((r: StrictHttpResponse<MessageDto>) => r.body as MessageDto)
    );
  }
/**
   * Path part for operation getChatStatus
   */
static readonly GetChatStatusPath = '/api/v1/chat/status';

/**
 * Get Chat Status.
 *
 * API to get Chat status whether handled by bot or Agent
 *
 * This method provides access to the full `HttpResponse`, allowing access to response headers.
 * To access only the response body, use `getChatStatus()` instead.
 *
 * This method sends `application/json` and handles request body of type `application/json`.
 */
getChatStatus$Response(params: {
  secretKey: string;
      body: AgentRequestDto
}
): Observable<StrictHttpResponse<boolean>> {

  const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetChatStatusPath, 'post');
  if (params) {
    rb.header('apiKey', params.secretKey, {});
    rb.body(params.body, 'application/json');
  }

  return this.http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    
  })).pipe(
    filter((r: any) => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
    })
  );
}

/**
 * Get Chat Status.
 *
 * API to get Chat status whether handled by bot or Agent
 *
 * This method provides access to only to the response body.
 * To access the full response (for headers, for example), `getChatStatus$Response()` instead.
 *
 * This method sends `application/json` and handles request body of type `application/json`.
 */
getChatStatus(params: {
  secretKey: string;
  
  body: AgentRequestDto
}
): Observable<boolean> {

  return this.getChatStatus$Response(params).pipe(
    map((r: StrictHttpResponse<boolean>) => r.body as boolean)
  );
}

  /**
   * Path part for operation getChannelChatRooms
   */
  static readonly GetChannelChatRoomsPath = '/api/v1/channel/chatRooms';

  /**
   * Get Channel Chat Rooms.
   *
   * API to get all channel related chat rooms
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChannelChatRooms()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getChannelChatRooms$Response(params: {
    active?: string;
    assigned?: string;
    page?: number;
    limit?: number;
    body: AgentRequestDto
  }
): Observable<StrictHttpResponse<ChatRoomDetailsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetChannelChatRoomsPath, 'post');
    if (params) {
      rb.query('active', params.active, {});
      rb.query('assigned', params.assigned, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChatRoomDetailsResponseDto>;
      })
    );
  }

  /**
   * Get Channel Chat Rooms.
   *
   * API to get all channel related chat rooms
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChannelChatRooms$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getChannelChatRooms(params: {
    active?: string;
    assigned?: string;
    page?: number;
    limit?: number;
    body: AgentRequestDto
  }
): Observable<ChatRoomDetailsResponseDto> {

    return this.getChannelChatRooms$Response(params).pipe(
      map((r: StrictHttpResponse<ChatRoomDetailsResponseDto>) => r.body as ChatRoomDetailsResponseDto)
    );
  }

/**
   * Path part for operation getAgentsByDepartmentAndChannel
   */
static readonly GetAgentsByDepartmentAndChannelPath = '/api/v1/channel/agents';

/**
 * Get all agents in a department of a channel.
 *
 * API to get all agents related to a channel
 *
 * This method provides access to the full `HttpResponse`, allowing access to response headers.
 * To access only the response body, use `getAgentsByDepartmentAndChannel()` instead.
 *
 * This method sends `application/json` and handles request body of type `application/json`.
 */
getAgentsByDepartmentAndChannel$Response(params: {
  departmentId?: string;
  page?: number;
  limit?: number;
  enable?: boolean;
  active?: string;
  body: AgentRequestDto
}): Observable<StrictHttpResponse<AgentResponseDto>> {

  const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetAgentsByDepartmentAndChannelPath, 'post');
  if (params) {
    rb.query('departmentId', params.departmentId, {});
    rb.query('page', params.page, {});
    rb.query('limit', params.limit, {});
    rb.query('enable', params.enable, {});
    rb.query('active', params.active, {});
    rb.body(params.body, 'application/json');
  }

  return this.http.request(rb.build({
    responseType: 'json',
    accept: 'application/json'
  })).pipe(
    filter((r: any) => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<AgentResponseDto>;
    })
  );
}

/**
 * Get all agents in a department of a channel.
 *
 * API to get all agents related to a channel
 *
 * This method provides access to only to the response body.
 * To access the full response (for headers, for example), `getAgentsByDepartmentAndChannel$Response()` instead.
 *
 * This method sends `application/json` and handles request body of type `application/json`.
 */
getAgentsByDepartmentAndChannel(params: {
  departmentId?: string;
  page?: number;
  limit?: number;
  enable?: boolean;
  active?: string;
  body: AgentRequestDto
}): Observable<AgentResponseDto> {

  return this.getAgentsByDepartmentAndChannel$Response(params).pipe(
    map((r: StrictHttpResponse<AgentResponseDto>) => r.body as AgentResponseDto)
  );
}


 /**
   * Path part for operation getSecretKey
   */
 static readonly GetSecretKeyPath = '/api/v1/secretKey';

 /**
  * Get Secret Key.
  *
  * API to get the secret key
  *
  * This method provides access to the full `HttpResponse`, allowing access to response headers.
  * To access only the response body, use `getSecretKey()` instead.
  *
  * This method doesn't expect any request body.
  */
 getSecretKey$Response(
): Observable<StrictHttpResponse<string>> {

   const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetSecretKeyPath, 'get');
   return this.http.request(rb.build({
     responseType: 'text',
      accept: 'text/plain'
   })).pipe(
     filter((r: any) => r instanceof HttpResponse),
     map((r: HttpResponse<any>) => {
       return r as StrictHttpResponse<string>;
     })
   );
 }

 /**
  * Get Secret Key.
  *
  * API to get the secret key
  *
  * This method provides access to only to the response body.
  * To access the full response (for headers, for example), `getSecretKey$Response()` instead.
  *
  * This method doesn't expect any request body.
  */
 getSecretKey(
): Observable<string> {

   return this.getSecretKey$Response().pipe(
     map((r: StrictHttpResponse<string>) => r.body as string)
   );
 }
 /**
   * Path part for operation enableDisableAgent
   */
 static readonly EnableDisableAgentPath = '/api/v1/channel/agent/{agentId}/enable-disable-agent';

 /**
  * This method provides access to the full `HttpResponse`, allowing access to response headers.
  * To access only the response body, use `enableDisableAgent()` instead.
  *
  * This method sends `application/json` and handles request body of type `application/json`.
  */
 enableDisableAgent$Response(params: {
  body: AgentRequestDto
   agentId: string;
   enable: boolean;
 }): Observable<StrictHttpResponse<void>> {

   const rb = new RequestBuilder(this.rootUrl, AgentControllerService.EnableDisableAgentPath, 'put');
   if (params) {
    rb.body(params.body, 'application/json');
     rb.path('agentId', params.agentId, {});
     rb.query('enable', params.enable, {});
   }

   return this.http.request(rb.build({
    responseType: 'json',
    accept: 'application/json'
   })).pipe(
     filter((r: any) => r instanceof HttpResponse),
     map((r: HttpResponse<any>) => {
       return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
     })
   );
 }

 /**
  * This method provides access to only to the response body.
  * To access the full response (for headers, for example), `enableDisableAgent$Response()` instead.
  *
  * This method sends `application/json` and handles request body of type `application/json`.
  */
 enableDisableAgent(params: {
  body: AgentRequestDto
   agentId: string;
   enable: boolean;
 }): Observable<void> {

   return this.enableDisableAgent$Response(params).pipe(
     map((r: StrictHttpResponse<void>) => r.body as void)
   );
 }
 
 
  /**
  * Path part for operation deleteAgent
  */
 static readonly DeleteAgentPath = '/api/v1/channel/agent/{agentId}/delete-agent';

 /**
  * This method provides access to the full `HttpResponse`, allowing access to response headers.
  * To access only the response body, use `deleteAgent()` instead.
  *
  * This method doesn't expect any request body.
  */
 deleteAgent$Response(params: {
   agentId: string;
 }): Observable<StrictHttpResponse<void>> {

   const rb = new RequestBuilder(this.rootUrl, AgentControllerService.DeleteAgentPath, 'delete');
   if (params) {
     rb.path('agentId', params.agentId, {});
   }

   return this.http.request(rb.build({
    responseType: 'json',
    accept: 'application/json'
   })).pipe(
     filter((r: any) => r instanceof HttpResponse),
     map((r: HttpResponse<any>) => {
       return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
     })
   );
 }

 /**
  * This method provides access to only to the response body.
  * To access the full response (for headers, for example), `deleteAgent$Response()` instead.
  *
  * This method doesn't expect any request body.
  */
 deleteAgent(params: {
   agentId: string;
 }): Observable<void> {

   return this.deleteAgent$Response(params).pipe(
     map((r: StrictHttpResponse<void>) => r.body as void)
   );
 }

 /**
 * Path part for operation getChatCounts
 */
static readonly GetChatCountsPath = '/api/v1/chat/counts';

/**
 * Get Chat counts.
 *
 * API to get chat overall counts, including counts of open and closed chats, live chats, and bot-controlled chats
 *
 * This method provides access to the full `HttpResponse`, allowing access to response headers.
 * To access only the response body, use `getChatCounts()` instead.
 *
 * This method sends `application/json` and handles request body of type `application/json`.
 */
getChatCounts$Response(params: {
  departmentId?: string;
    body: AgentRequestDto
}): Observable<StrictHttpResponse<ChatCountsDto>> {

  const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetChatCountsPath, 'post');
  if (params) {
    rb.query('departmentId', params.departmentId, {});
    rb.body(params.body, 'application/json');
  }
  return this.http.request(rb.build({
   responseType: 'json',
    accept: 'application/json'
  })).pipe(
    filter((r: any) => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ChatCountsDto>;
    })
  );
}

/**
 * Get Chat counts.
 *
 * API to get chat overall counts, including counts of open and closed chats, live chats, and bot-controlled chats
 *
 * This method provides access to only to the response body.
 * To access the full response (for headers, for example), `getChatCounts$Response()` instead.
 *
 * This method sends `application/json` and handles request body of type `application/json`.
 */
getChatCounts(params: {
  departmentId?: string;
    body: AgentRequestDto
}): Observable<ChatCountsDto> {
  return this.getChatCounts$Response(params).pipe(
    map((r: StrictHttpResponse<ChatCountsDto>) => r.body as ChatCountsDto)
  );
}


  /**
   * Path part for operation getHourlyChatCounts
   */
  static readonly GetHourlyChatCountsPath = '/api/v1/hourly-chats/count';

  /**
   * Get Hourly Chat counts.
   *
   * API to get hourly chats count , including assigned , new and waiting for assignment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getHourlyChatCounts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getHourlyChatCounts$Response(params: {
    departmentId?: string;
    body: AgentRequestDto
  }
): Observable<StrictHttpResponse<Array<HourlyChatsCountDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetHourlyChatCountsPath, 'post');
    if (params) {
      rb.query('departmentId', params.departmentId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
       accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<HourlyChatsCountDto>>;
      })
    );
  }

  /**
   * Get Hourly Chat counts.
   *
   * API to get hourly chats count , including assigned , new and waiting for assignment
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getHourlyChatCounts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getHourlyChatCounts(params: {
    departmentId?: string;
    body: AgentRequestDto
  }): Observable<Array<HourlyChatsCountDto>> {

    return this.getHourlyChatCounts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<HourlyChatsCountDto>>) => r.body as Array<HourlyChatsCountDto>)
  );
}

}
