import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { PlatformAdminUtilityControllerService } from 'src/app/services/Platform/services';
import { AgentDto } from 'src/app/services/Whatsapp-agent/models/agent-dto';
import { AgentControllerService, DepartmentControllerService } from 'src/app/services/Whatsapp-agent/services';
import { BACK_ARROW, CHEVRON_LEFT, WHATSAPP_ICON } from 'src/app/shared/constants/strings';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { DELETE_IMAGE } from 'src/app/shared/constants/strings';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BciLoaderService } from '@bci-web-core/core';
import { TranslationService } from 'src/app/providers/translation-service';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { AgentRequestDto } from 'src/app/services/Whatsapp-agent/models';

@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.scss']
})
export class AgentListComponent implements OnInit {
  public whatsappIcon: string;
  public dropDownValues: any = {};
  public channelId: string;
  agentId: string;
  agentList: any;
  public dataSource: MatTableDataSource<AgentDto>;
  public columns: any[];
  deleteImage;
  public departments = [];
  public selectedDepartment: string = '';
  public ignoreList = ['userId', 'channelNumber', 'departmentId', 'workDuration', 'closedChatsCount', 'activeChatsCount', 'enabled', 'deleted','userRating'];
  wabaNumber: any;
  showSpinner: any;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageSize: number = this.pageSizeOptions[0];
  pageIndex: number = 0;
  public totalAgents = 0;
  backImage: string;
    defaultDepartment: any;

  constructor(
    private responseHandlerService: ResponseHandlerService,
    private agentControllerService: AgentControllerService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private departmentControllerService: DepartmentControllerService,
    private dialog: MatDialog,
    private router: Router,
    private loaderService: BciLoaderService,
    private translate: TranslationService,
  ) {}

  ngOnInit(): void {
    this.whatsappIcon = WHATSAPP_ICON;
    this.getWabaNumber();
    this.deleteImage = DELETE_IMAGE;
    this.backImage = CHEVRON_LEFT;
  }

  backToAgentConfig() {
    this.router.navigate(['whatsapp/agent-config']);
  }

  getWabaNumber() {
    this.platformAdminUtilityControllerService.getUserAccountDetails().subscribe((resp) => {
      if (resp) {
        this.wabaNumber = resp['wabaNumber'];
        this.getDepartmentsList(this.wabaNumber);
      }
    }, (err) => {
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }


  getDepartmentsList(channelNumber) {
    const body: AgentRequestDto = { channelNumber };
    const params = {
      body,
    };
    this.departmentControllerService.getDepartmentsByChannelNumber(params).subscribe(
      resp => {
        if (resp && resp.length) {
          const response = resp.map(item => ({
            id: item.id,
            name: item.departmentName
          }));
          this.dropDownValues['departmentId'] = response;
          this.departments = response; 
          if (this.departments.length > 0) {
            this.defaultDepartment = this.departments[0].id; 
          
             this.selectedDepartment = this.departments[0].id === 'Default' ? this.wabaNumber : this.departments[0].id;

            this.getAgentDetailsList(this.wabaNumber, this.pageIndex, this.pageSize, this.selectedDepartment); 
          }
        } else {
          this.dropDownValues['departmentId'] = [];
        }
      },
      (err) => {
        this.dropDownValues['channel'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

  getAgentDetailsList(channelNumber, page, limit,departmentId) {
    const body: AgentRequestDto = { channelNumber };
    const params = { body, 
      departmentId: departmentId,
      page, limit, };

    this.agentControllerService.getAgentsByDepartmentAndChannel(params).subscribe(
      (resp) => {
        if (resp && resp.agentList) {
          this.agentList = resp.agentList;
          this.totalAgents = resp.totalAgents;
          if (this.agentList && this.agentList.length > 0) {
            this.dataSource = new MatTableDataSource<AgentDto>(this.agentList);
            for (const data of this.agentList) {
              data['action'] = null;
            }
            this.columns = this.filterColumns(Object.keys(this.agentList[0]));
          } else {
            this.dataSource = new MatTableDataSource<AgentDto>([]);
            this.responseHandlerService.returnToastMessage('error', 'NO_AGENTS');
          }
        }
      }
    );
  }

  
  onDepartmentChange(event: any) {
    if (event.value !== undefined) {
    if (event.value == 'Default') {
        this.getAgentDetailsList(this.wabaNumber, this.pageIndex, this.pageSize, this.wabaNumber);
    } else {
        this.selectedDepartment = event.value;
        this.getAgentDetailsList(this.wabaNumber, this.pageIndex, this.pageSize, event.value);
    } 
}
  }

  public pageEvent(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAgentDetailsList(this.wabaNumber, this.pageIndex, this.pageSize, this.selectedDepartment);
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => !this.ignoreList.includes(item));
    }
    return [];
  }

public onClose() {
    this.dialog.closeAll();
}
public gotoConfirmDialog(agentData: any, action: string) {
  this.agentList = agentData;
  let title: string;
  let message: string;

  if (action === 'enabled') {
      message = `${agentData.enabled ? 'Enable' : 'Disable'} ${this.translate.translateErrorMessages('AGENT_ENABLE_DISABLE')} `;
  } else if (action === 'deleted') {
      message = `${this.translate.translateErrorMessages('AGENT_DELETE')} `;
  }

  const dialogRef = this.dialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      data: { title, message }
  });

  dialogRef.afterClosed().subscribe(result => {
      if (result) {
          
          if (action === 'enabled') {
              this.enableDisableAgent(agentData);
          } else if (action === 'deleted') {
              this.deleteAgent(agentData);
          }
      } else {
          
          if (action === 'enabled') {
              this.abortAgentAction(agentData);
          }
      }
  });
}

public abortAgentAction(agentData: any) {
  this.dialog.closeAll();
  agentData.enabled = !agentData.enabled; 
}


enableDisableAgent(agentData: any) {
  this.dialog.closeAll();
  this.showSpinner = this.loaderService.showProgressBar();
  this.agentControllerService.enableDisableAgent({
    body: {
      channelNumber: this.wabaNumber 
    },
      agentId: agentData.userId,
      enable: agentData.enabled
  }).subscribe(
      (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          const successMessage = agentData.enabled ? 'AGENT_ENABLED' : 'AGENT_DISABLED';
          this.responseHandlerService.returnToastMessage('success', successMessage);
          this.getAgentDetailsList(this.wabaNumber, this.pageIndex, this.pageSize,this.selectedDepartment);
      },
      err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
          agentData.enabled = !agentData.enabled; 
      }
  );
}

deleteAgent(agentData: any) {
  this.dialog.closeAll();
  this.showSpinner = this.loaderService.showProgressBar();
  this.agentControllerService.deleteAgent({
      agentId: agentData.userId
  }).subscribe(
      (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'AGENT_DELETED');
          this.getAgentDetailsList(this.wabaNumber, this.pageIndex, this.pageSize, this.selectedDepartment);
      },
      err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
  );
}
}