import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textFormat'
})
export class TextFormatPipe implements PipeTransform {
    transform(value: string | null | undefined): string {
    if (value === null || value === undefined) {
      return '';
    }
      let formattedContent = value;
      formattedContent = formattedContent.replace(/\*([^\*\s][^\*\n]*[^\*\s])\*/g, '<b>$1</b>');
      formattedContent = formattedContent.replace(/\_([^\_\s][^\_\n]*[^\_\s])\_/g, '<i>$1</i>');
      formattedContent = formattedContent.replace(/\~([^\~\s][^\~\n]*[^\~\s])\~/g, '<s>$1</s>');
      formattedContent = formattedContent.replace(/\n/g, '<br>');
      return formattedContent;
    }
}