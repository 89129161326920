<section class="overview-container">
    <section class="main">
        <div class="header-style">
            <span class="heading-label">
                <img class="back-arrow" [src]="backImage" alt="Back" (click)="backToAgentConfig()" />
                <img class="page-icon-small" [src]="whatsappIcon" />
                <span>{{ 'AGENT_LIST' | translate }}</span>
            </span>
        </div>

        <div class="form-spacing" *ngIf="departments && departments.length">
          <mat-form-field class="select-box">
              <mat-label class="label" >{{ 'DEPARTMENT_NAME' | translate }} <span class="mandatory">*</span></mat-label>
              <mat-select placeholder="Select Department" [(ngModel)]="defaultDepartment" (selectionChange)="onDepartmentChange($event)">
                  <mat-option *ngFor="let department of departments" [value]="department.id">
                      {{ department.name }}
                  </mat-option>
              </mat-select>
          </mat-form-field>
      </div>
      
        <div class="table-container" *ngIf="agentList">
            <mat-table [dataSource]="dataSource" class="table-data">
              <div *ngFor="let column of columns">
                <ng-container [matColumnDef]="column">
                  <mat-header-cell *matHeaderCellDef>{{column | appendSpace | titlecase}}</mat-header-cell>
                  <mat-cell class="tooltip" *matCellDef="let dataName">
                    <span *ngIf="column!=='action'" title="{{dataName[column]}}" class="cell-value">
                      {{dataName[column]}}
                    </span>
                    <span *ngIf="column==='action'" class="action-container">
                      <mat-slide-toggle 
                      [(ngModel)]="dataName['enabled']" (change)="gotoConfirmDialog(dataName, 'enabled')">
                      </mat-slide-toggle>
                      <img [src]="deleteImage"
                        (click)="gotoConfirmDialog(dataName, 'deleted')" class="modify-button delete-icon">
                    </span>
                  </mat-cell>
                </ng-container>
              </div>>
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let data; columns: columns;"></mat-row>
            </mat-table>
        </div>
       
        <mat-paginator *ngIf="dataSource?.data?.length > 0" [pageSizeOptions]="pageSizeOptions" [length]="totalAgents" [pageSize]="pageSize"
            showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>

    </section>
</section>