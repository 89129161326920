import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { PlatformAdminUtilityControllerService } from 'src/app/services/Platform/services';
import { AgentDto } from 'src/app/services/Whatsapp-agent/models/agent-dto';
import { AgentControllerService, DepartmentControllerService } from 'src/app/services/Whatsapp-agent/services';
import { WhatsappProfileConnectionDetailsControllerService } from 'src/app/services/Whatsapp/services';
import { WHATSAPP_ICON } from 'src/app/shared/constants/strings';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { DELETE_IMAGE } from 'src/app/shared/constants/strings';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { BciLoaderService } from '@bci-web-core/core';
import { TranslationService } from 'src/app/providers/translation-service';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { AgentRequestDto } from 'src/app/services/Whatsapp-agent/models';

@Component({
  selector: 'app-agent-config',
  templateUrl: './agent-config.component.html',
  styleUrls: ['./agent-config.component.scss']
})
export class AgentConfigComponent implements OnInit {
  public accountId: string;
  public tenantId: string;
  public profileId: string;
  public whatsappIcon: string;
  public dropdownList: [];
  public dropDownValues: any = {};
  public formData;
  public profileIdCheck = true;
  public channelId:string;
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  agentId: string;
  agentList:any;
  public dataSource;
  public columns: any[];
  deleteImage;
  public ignoreList = ['userId','channelNumber','departmentId','workDuration','closedChatsCount','activeChatsCount','enabled','deleted'];
  wabaNumber: any;
  showSpinner: any;
  closeIcon: string;
 
  constructor(
    private responseHandlerService: ResponseHandlerService,
    private agentControllerService:AgentControllerService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private departmentControllerService:DepartmentControllerService,
    private dialog: MatDialog,
    private loaderService: BciLoaderService,
    private translate: TranslationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.whatsappIcon = WHATSAPP_ICON;
    this.getWabaNumber();
    this.deleteImage = DELETE_IMAGE;
  }
  viewAgentList() {
    this.router.navigate(['/whatsapp/agent-list']);  
  }

  createAgent(data) {
    const departmentId = data.departmentId === 'Default' ? this.wabaNumber : data.departmentId;
    if(data.agentDuration =='CUSTOM_BUSINESS_HOUR'){
    if (data.startTime === data.endTime) {
      this.responseHandlerService.returnToastMessage('error','START_TIME_AND_END_TIME_CAN_NOT_BE_SAME');
      return;
    }
  }
    const reqObj = {
      body: {
        departmentId:departmentId,
        channelNumber:this.wabaNumber,
        emailId: data.emailId,
        firstName: data.firstName,
        lastName: data.lastName,
        workStartTime: data.startTime ? data.startTime : '00:00',
        workEndTime: data.endTime ? data.endTime : '23:59'
      },
    };
    this.agentControllerService.createAgent(reqObj).subscribe((resp) => {
        if (resp) {
          this.agentId = resp.message;
          this.responseHandlerService.returnToastMessage(
            'success',
            'AGENT_CREATED_SUCCESS'
          );
          this.onReset();
        } 
      },
    (err) => {
      this.responseHandlerService.returnToastMessage(
        'error',
        err.error.message
      );
    }
  );
  }

  public onReset() {
    this.dynamicForm.createForm.reset();
    this.dynamicForm.createForm.controls['agentDuration'].setValue('24_Hours_7_Days');
    this.dropDownValues['departmentId'] = [];
    this.getDepartmentsList(this.wabaNumber);
  }

  

 
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  getWabaNumber() {
    this.platformAdminUtilityControllerService
    .getUserAccountDetails().subscribe((resp) => {
      if (resp) {
        this.wabaNumber = resp['wabaNumber'];
    
    this.getDepartmentsList( this.wabaNumber);
      }
    },(err) => {
      this.responseHandlerService.returnToastMessage(
        'error',
        err.error.message
      );
    });
  }

  getDepartmentsList(channelNumber) {
    const body: AgentRequestDto = { channelNumber };
    const params = {
      body,
    };
    this.departmentControllerService.getDepartmentsByChannelNumber(params).subscribe(
      resp=> {
      if (resp && resp.length) {
        const response = resp.map(item => ({
          'id': item.id,
          'name': item.departmentName
        }));
        this.dropDownValues['departmentId'] = response;
      } else {
        this.dropDownValues['departmentId'] = [];
      }
    },
    (err) => {
      this.dropDownValues['channel'] = [];
      this.responseHandlerService.returnToastMessage(
        'error',
        err.error.message
      );
    });
  }
}