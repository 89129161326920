import { Component, OnInit, OnDestroy,  ViewChild, ElementRef } from '@angular/core';
import {
  WHATSAPP_ICON,
  CHEVRON_LEFT,
  INFO_ICON_IMAGE,
  DOWNLOAD_ICON_SMALL,
  SUPPORTED_WHATSAPP_DOCUMENT_FORMAT,
  SUPPORTED_WHATSAPP_IMAGE_FORMAT,
  SUPPORTED_WHATSAPP_VIDEO_FORMAT,
  WHATSAPP_DARK_BACKGROUND,
  WHATSAPP_LIGHT_BACKGROUND,
  WHATSAPP_DOCUMENT,
  WHATSAPP_LOCATION, CLOSE_ICON, CLOSE_BTN, GIFT_ICON
} from 'src/app/shared/constants/strings';
import {
  AccountControllerService,
  TenantControllerService,
  BusinessProfileControllerService,
} from 'src/app/services/Platform/services';
import { WhatsappProfileConnectionDetailsControllerService, WhatsappTemplateControllerService } from 'src/app/services/Whatsapp/services';
import { AgentControllerService } from 'src/app/services/Whatsapp-agent/services';
import { IncomingMessageControllerService } from 'src/app/services/Whatsapp-agent/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BciLoaderService } from '@bci-web-core/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvService } from 'src/app/env.service';
import { SwiperContainer } from 'swiper/element';
import { register } from 'swiper/element/bundle';
	register();
  
@Component({
  selector: 'app-create-whatsapp-template',
  templateUrl: './create-whatsapp-template.component.html',
  styleUrls: ['./create-whatsapp-template.component.scss']
})
export class CreateWhatsappTemplateComponent implements OnInit, OnDestroy {
  @ViewChild('swiperRef', { static: false }) swiperRef: ElementRef<SwiperContainer>;
  @ViewChild('previewSwiperRef', { static: false }) previewSwiperRef: ElementRef<SwiperContainer>;
  public permissionList: Array<String> = [];
  public jsonFileLoaded;
  public whatsappIcon;
  public downloadIcon;
  public backImage;
  public infoIcon;
  public submitted = false;
  public isAuthTemplate = false;
  public isCarouselSelected = false;
  public carouselIndex : number;
  public showSpinner:any;
  public dropDownValues: any = {};
  public accountId: string;
  public tenantId: string;
  public profileId: string;
  public templateId: string;
  public fileForUpload;
  public invalidFile;
  public uploadedFileName;
  public carouselMediaFileNames: string[] = [];
  public fileUrl;
  public videoFormat;
  public imageFormat;
  public documentFormat;
  public supportedMediaType;
  public callPhoneType;
  public selectedTypeOfActionOne;
  public selectedTypeOfActionTwo;
  public disablePointer = false;
  public templateForm: FormGroup;
  public quickReplyFormArray: FormArray;
  public callToActionFormArray: FormArray;
  public carouselFormArray: FormArray;
  public dataLoaded = new BehaviorSubject<any>(false);
  public maxCharacterContentCount = 1024;
  public maxCharacterCarouselContent = 160;
  public characterContentCount = 0;
  public maxCharacterFooterCount = 60;
  public characterFooterCount = 0;
  public characterContentCounts: number[] = [0];
  public showEmojiPickerCarousel = false;
  showEmojiPicker = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ];
  set = 'twitter';
public lightMode = true;
public optionsPopup = false;
public whatsAppDocumentImg;
public whatsAppLocationImg;
public whatsAppDarkImg;
public whatsAppLightImg;
public currentTime;
public closeIcon;
public closeBtn;
public giftIcon;
public disableFooterPointer = false;
public channelId: string;
public expiryTime: string;
public disableSecurityPointer = false;
public disableExpiryPointer = false;
public footerText: boolean;
public secretKey:string;
  mediaURL: string;
 public isLtoSelected: boolean;

  constructor(
    private accountControllerService: AccountControllerService,
    private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private route: ActivatedRoute,
    private responseHandlerService: ResponseHandlerService,
    private _matDialog: MatDialog,
    private whatsappTemplateControllerService: WhatsappTemplateControllerService,
    private router: Router,
    private loaderService: BciLoaderService, private translate: TranslateService,
    private whatsappProfileConnectionDetailsControllerService: WhatsappProfileConnectionDetailsControllerService,
    private agentControllerService: AgentControllerService,
    private incomingMessageControllerService: IncomingMessageControllerService,
    private env: EnvService
  ) {}

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.jsonFileLoaded = 'whatsapp-create-template';
    this.whatsappIcon = WHATSAPP_ICON;
    this.backImage = CHEVRON_LEFT;
    this.infoIcon = INFO_ICON_IMAGE;
    this.downloadIcon = DOWNLOAD_ICON_SMALL;
    this.videoFormat = SUPPORTED_WHATSAPP_VIDEO_FORMAT;
    this.documentFormat = SUPPORTED_WHATSAPP_DOCUMENT_FORMAT;
    this.imageFormat = SUPPORTED_WHATSAPP_IMAGE_FORMAT;
    this.whatsAppDarkImg = WHATSAPP_DARK_BACKGROUND;
    this.whatsAppLightImg = WHATSAPP_LIGHT_BACKGROUND;
    this.whatsAppDocumentImg = WHATSAPP_DOCUMENT;
    this.whatsAppLocationImg = WHATSAPP_LOCATION;
    this.closeIcon = CLOSE_ICON;
    this.closeBtn = CLOSE_BTN;
    this.giftIcon = GIFT_ICON;
    this.currentTime = new Date();
    this.accountId = this.route.snapshot.paramMap.get('accountId');
    this.tenantId = this.route.snapshot.paramMap.get('tenantId');
    this.profileId = this.route.snapshot.paramMap.get('profileId');
    this.templateId = this.route.snapshot.paramMap.get('templateId');
    this.channelId = this.route.snapshot.paramMap.get('channelId');
    this.createTemplateForm();
    this.getTypeOfActions();
    this.getAccount();
    this.getLanguages();
    this.getTemplateCategories();
    this.getHeaderType();
    this.getButtonsType();
    this.loadRouteData();
    this.getSecretKey();
  }

  public createTemplateForm() {
    this.templateForm = new FormGroup({
      'account': this.getFormControl(),
      'tenant': this.getFormControl(),
      'businessProfile': this.getFormControl(),
      'channel': this.getFormControl(),
      'language': this.getFormControl(),
      'category': this.getFormControl(),
      'whatsappTemplateName': new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9_]+$')]),
      'whatsappTemplateContent': this.getFormControl(true, 1024),
      'footer': this.getFormControl(false, 60),
      'buttonsType': this.getFormControl(false),
      'headerType': this.getFormControl(false),
    });
  }

  public updateDropdownValues($event) {
    if ($event.value) {
      if ($event.hasOwnProperty('child')) {
        if ($event.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
        }
        if ($event.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if ($event.child === 'channel') {
          this.profileId = $event.value;
          this.getChannel($event.value);
        }
        if ($event.child === 'language') {
          this.channelId = $event.value;
        }
        if ($event.child === 'header') {
          this.fileUrl = null;
          this.fileForUpload = null;
          this.uploadedFileName = null;
          this.carouselMediaFileNames = [];
          this.supportedMediaType = null;
          this.removeControlsOnReset();
          this.templateForm.controls['buttonsType'].reset();
          if ($event.value['name'] === 'Text') {
            this.addFormControl('header', this.getFormControl(true, 60));
          } else if ($event.value['name'] === 'Carousel') {
            this.isCarouselSelected = true;
            this.showEmojiPickerCarousel = false;
            this.supportedMediaType = this.imageFormat;
            this.removeFormControl('footer');
            this.removeFormControl('buttonsType');
            this.removeFormControl('header');
            this.removeFormControl('file');
            if (this.templateForm.controls['carouselItems']) {
              this.templateForm.controls['carouselItems'].reset();
            } else {
              this.carouselFormArray = new FormArray([
                new FormGroup({
                  carouselContent: this.getFormControl(true, 160),
                  carouselMediaType: new FormControl('image', [Validators.required]),
                  carouselMediaFile: this.getFormControl(),
                  carouselButtonsType: this.getFormControl(true),
                  carouselQuickReply: new FormArray([]),
                  carouselCallToActionFormArray: new FormArray([]),
                }),
              ]);
              this.addFormControl('carouselItems', this.carouselFormArray);
            }
          } else if($event.value['name'] === 'Limited time offer') {
            this.isLtoSelected = true;
            this.isCarouselSelected = false;
            this.removeFormControl('footer');
            this.addFormControl('ltoText',this.getFormControl(true, 16));
            this.addFormControl('ltoMediaType',new FormControl('',[]));
            this.addFormControl('isltoExpiryCode',new FormControl('No', [Validators.required]));
            this.addFormControl('ltoOfferCode',this.getFormControl(true,15));
            this.templateForm.controls['buttonsType'].setValue(this.dropDownValues['buttonsType'][1]);
            const setOption = {
              child: 'buttonText',
              value: this.dropDownValues['buttonsType'][1],
            };
            this.updateDropdownValues(setOption);
            const actionFormGroup = this.templateForm.get(
              'callToActionArr'
            ) as FormArray;
            const formGroup = actionFormGroup['controls'][0] as FormGroup;
            formGroup.get('typeOfAction')?.setValue(this.dropDownValues['typeOfAction'][1]);
          } else {
            this.removeFormControl('carouselItems');
            this.addFormControl('file', this.getFormControl());
            if ($event.value['name'] === 'Video') {
              this.supportedMediaType = this.videoFormat;
            }
            if ($event.value['name'] === 'Image') {
              this.supportedMediaType = this.imageFormat;
            }
            if ($event.value['name'] === 'Document') {
              this.supportedMediaType = this.documentFormat;
            }
            if ($event.value['name'] === 'Location') {
              this.removeFormControl('file');
              this.removeFormControl('header');
            }
          }
        }
        if ($event.child === 'buttonText') {
          this.templateForm.controls['quickReply']
            ? this.templateForm.controls['quickReply'].reset()
            : '';
          this.templateForm.controls['callToActionArr']
            ? this.templateForm.controls['callToActionArr'].reset()
            : '';
          this.quickReplyFormArray = new FormArray([
            new FormGroup({
              buttonText: this.getFormControl(true, 25),
            }),
          ]);
          this.callToActionFormArray = new FormArray([
            new FormGroup({
              typeOfAction: new FormControl(
                this.callPhoneType,
                Validators.required
              ),
            }),
          ]);
          this.removeFormControl('callToActionArr');
          this.removeFormControl('quickReply');
          this.selectedTypeOfActionOne = null;
          this.selectedTypeOfActionTwo = null;
          if ($event.value['name'] === 'Quick reply') {
            this.addFormControl('quickReply', this.quickReplyFormArray);
          } else {
            this.addFormControl('callToActionArr', this.callToActionFormArray);
            const setOption = {
              child: 'actionType',
              value: this.isLtoSelected && this.dropDownValues?.['typeOfAction']?.[1] != null 
                        ? this.dropDownValues['typeOfAction'][1] : this.callPhoneType,
              index: 0
            };
            if (!this.disablePointer) {
              this.updateDropdownValues(setOption);
            }
          }
        }
        if ($event.child === 'actionType') {
          if ($event['index'] === 0) {
            this.selectedTypeOfActionOne = $event.value['name'];
          } else if ($event['index'] === 1) {
            this.selectedTypeOfActionTwo = $event.value['name'];
          }
          if(this.isCarouselSelected) {
            const formArray = this.templateForm.get('carouselItems') as FormArray;
            formArray.controls.forEach((carouselItemControl, index) => {
              const carouselItem = carouselItemControl as FormGroup;
              const carouselCallToActionFormArray = carouselItem.get('carouselCallToAction') as FormArray;
              if (carouselCallToActionFormArray) { 
                const formGroup = carouselCallToActionFormArray.controls[$event['index']] as FormGroup;
                if(index !== 0) {
                  formGroup.get('typeOfAction').setValue($event.value);
                }
                if ($event.value['name'] === 'Visit Website') {
                  formGroup.removeControl('phoneNumber');
                  formGroup.controls['buttonText']
                    ? formGroup.controls['buttonText'].reset()
                    : formGroup.addControl(
                        'buttonText',
                        this.getFormControl(true, 25)
                      );
                    formGroup.addControl(
                      'websiteUrl',
                      new FormControl('', [
                        Validators.required,
                        Validators.pattern('^(http:\/\/|https:\/\/)(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$')]));
                } else {
                  formGroup.controls['buttonText']
                    ? formGroup.controls['buttonText'].reset()
                    : formGroup.addControl(
                        'buttonText',
                        this.getFormControl(true, 25)
                      );
                  formGroup.removeControl('websiteUrl');
                  formGroup.addControl(
                    'phoneNumber',
                    new FormControl('', [
                      Validators.required,
                      Validators.pattern(/^\+[1-9][0-9]{1,14}$/),
                    ])
                  );
                }
              }
            });
          } else {
            const actionFormGroup = this.templateForm.get(
              'callToActionArr'
            ) as FormArray;
            const formGroup = actionFormGroup['controls'][
              $event['index']
            ] as FormGroup;
            if ($event.value['name'] === 'Visit Website') {
              formGroup.removeControl('phoneNumber');
              formGroup.controls['buttonText']
                ? formGroup.controls['buttonText'].reset()
                : formGroup.addControl(
                    'buttonText',
                    this.getFormControl(true, 25)
                  );
              formGroup.addControl('urlType', this.getFormControl(false));
              formGroup.addControl(
                'websiteUrl',
                new FormControl('', [
                  Validators.required,
                  Validators.pattern('^(http:\/\/|https:\/\/)(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$')]));
            } else {
              formGroup.controls['buttonText']
                ? formGroup.controls['buttonText'].reset()
                : formGroup.addControl(
                    'buttonText',
                    this.getFormControl(true, 25)
                  );
              formGroup.removeControl('urlType');
              formGroup.removeControl('websiteUrl');
              formGroup.addControl(
                'phoneNumber',
                new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^\+[1-9][0-9]{1,14}$/),
                ])
              );
            }
          } 
        }
        if ($event.child === 'authenticationTemplate') {
          this.removeControlsOnReset();
          if ($event.value['name'] === 'AUTHENTICATION') {
            this.isCarouselSelected = false;
            this.isLtoSelected = false;
            this.isAuthTemplate = true;
            this.removeFormControl('footer');
            this.removeFormControl('buttonsType');
            this.removeFormControl('headerType');
            const authMessage = this.getTranslatedValues('AUTH_TEMPLATE_MSG');
            this.templateForm.get('whatsappTemplateContent').setValue(authMessage);
            this.addFormControl('securityMessage',new FormControl(this.disableSecurityPointer, [])) ;
            this.addFormControl('expiryTime',new FormControl(this.disableExpiryPointer, []));
            this.addFormControl('copyCodeButton',this.getFormControl(true, 25));
          } else {
            this.templateForm.get('whatsappTemplateContent').setValue('');
            this.isAuthTemplate = false;
            this.removeFormControl('securityMessage');
            this.removeFormControl('expiryTime');
            this.removeFormControl('copyCodeButton');
            this.addFormControl('footer', this.getFormControl(false, 60));
            this.addFormControl('buttonsType', this.getFormControl(false));
            this.addFormControl('headerType', this.getFormControl(false));
          }
        }
      }
      if ($event.child === 'carouselButtonText') {
        this.carouselFormArray.controls.forEach((carouselItem, index) => {
          const carouselGroup = carouselItem as FormGroup;
          carouselGroup.removeControl('carouselQuickReply');
          carouselGroup.removeControl('carouselCallToAction');
          this.selectedTypeOfActionOne = null;
          this.selectedTypeOfActionTwo = null;
          if(index !== 0) {
            carouselItem.get('carouselButtonsType').setValue($event.value);
          }
          if ($event.value['name'] === 'Quick reply') {
            const carouselQuickReplyFormArray = new FormArray([
              new FormGroup({
                buttonText: this.getFormControl(true, 25),
              }),
            ]);
            carouselGroup.addControl('carouselQuickReply', carouselQuickReplyFormArray);
          } else if($event.value['name'] === 'Call to action') {
            const carouselCallToActionFormArray = new FormArray([
              new FormGroup({
                typeOfAction: new FormControl(
                  this.callPhoneType,
                  Validators.required
                ),
                buttonText: this.getFormControl(true, 25),
              }),
            ]);
            carouselGroup.addControl('carouselCallToAction', carouselCallToActionFormArray);
            const setOption = {
              child: 'actionType',
              value: this.callPhoneType,
              index: 0
            };
            if (!this.disablePointer) {
              this.updateDropdownValues(setOption);
            }
          }
        });
      }
    } else {
      if ($event.child === 'header') {
        this.fileForUpload = null;
        this.uploadedFileName = null;
        this.carouselMediaFileNames = [];
        this.supportedMediaType = null;
        this.templateForm.controls['buttonsType'].reset();
        this.removeControlsOnReset();
      }
      if ($event.child === 'buttonText') {
        this.templateForm.controls['quickReply']
          ? this.templateForm.controls['quickReply'].reset()
          : '';
        this.quickReplyFormArray = new FormArray([
          new FormGroup({
            buttonText: this.getFormControl(true, 25),
          }),
        ]);
        this.removeFormControl('quickReply');
        this.templateForm.controls['callToActionArr']
          ? this.templateForm.controls['callToActionArr'].reset()
          : '';
        this.callToActionFormArray = new FormArray([
          new FormGroup({
            callToAction: this.getFormControl(),
          }),
        ]);
        this.removeFormControl('callToActionArr');
        this.selectedTypeOfActionOne = null;
        this.selectedTypeOfActionTwo = null;
      }
      if ($event.child === 'actionType') {
        const actionFormGroup = this.templateForm.get(
          'callToActionArr'
        ) as FormArray;
        const formGroup = actionFormGroup['controls'][
          $event['index']
        ] as FormGroup;
        formGroup.removeControl('phoneNumber');
        formGroup.removeControl('buttonText');
        formGroup.removeControl('urlType');
        formGroup.removeControl('websiteUrl');
        if ($event['index'] === 0) {
          this.selectedTypeOfActionOne = null;
        } else {
          this.selectedTypeOfActionTwo = null;
        }
      }
      if($event.child === 'authenticationTemplate') {
        this.templateForm.get('whatsappTemplateContent').setValue('');
        this.isAuthTemplate = false;
        this.removeFormControl('setExpiryTime');
        this.removeFormControl('copyCodeButton');
        this.addFormControl('footer', this.getFormControl(false, 60));
        this.addFormControl('buttonsType', this.getFormControl(false));
        this.addFormControl('headerType', this.getFormControl(false));
      }
    }
  }

  public onSubmit() {
    this.submitted = true;
    let phoneNumberAction;
    let visitWebsiteAction;
    const carouselItemsList: any[]= [];
    if (this.templateForm.valid) {
      this.showSpinner = this.loaderService.showProgressBar();
      const formValue = this.templateForm.value;
      if (formValue['callToActionArr'] && formValue['callToActionArr'].length) {
        formValue['callToActionArr'].forEach((element) => {
          if (element['typeOfAction']['name'] === 'Call Phone Number') {
            phoneNumberAction = element;
          } else {
            visitWebsiteAction = element;
          }
        });
      }
      if(formValue['carouselItems'] && formValue['carouselItems'].length) {
        formValue['carouselItems'].forEach(item => {
          let callPhoneNumberButtontype = null;
          let visitWebsiteButtonType = null;

          if (item['carouselCallToAction'] && item['carouselCallToAction'].length) {
            item['carouselCallToAction'].forEach((element) => {
              if (element['typeOfAction']['name'] === 'Call Phone Number') {
                callPhoneNumberButtontype = element;
              } else {
                visitWebsiteButtonType = element;
              }
            });
          }
          carouselItemsList.push({
            carouselContent: item['carouselContent'] || null,
            mediaUrl: item['carouselMediaFile'] || null,
            firstButton: item['carouselQuickReply'] && item['carouselQuickReply'][0] ? 
                          item['carouselQuickReply'][0]['buttonText'] : null,
            secondButton: item['carouselQuickReply'] && item['carouselQuickReply'][1]
                            ? item['carouselQuickReply'][1]['buttonText'] : null,
            callPhoneNumberButtontype: {
              buttonSupportTypeName:
              callPhoneNumberButtontype && callPhoneNumberButtontype['typeOfAction']
                    ? callPhoneNumberButtontype['typeOfAction']['name'] : null,
                buttonText:
                callPhoneNumberButtontype && callPhoneNumberButtontype['buttonText']
                    ? callPhoneNumberButtontype['buttonText'] : null,
                phoneNumber:
                callPhoneNumberButtontype && callPhoneNumberButtontype['phoneNumber']
                    ? callPhoneNumberButtontype['phoneNumber'] : null,
            },
            visitWebsiteButtonType: {
              buttonSupportTypeName:
                visitWebsiteButtonType && visitWebsiteButtonType['typeOfAction']
                    ? visitWebsiteButtonType['typeOfAction']['name'] : null,
                buttonText:
                  visitWebsiteButtonType && visitWebsiteButtonType['buttonText']
                    ? visitWebsiteButtonType['buttonText'] : null,
                websiteUrl:
                  visitWebsiteButtonType && visitWebsiteButtonType['websiteUrl']
                    ? visitWebsiteButtonType['websiteUrl'] : null,
                isLinkTrackingEnabled:
                  visitWebsiteButtonType && visitWebsiteButtonType['urlType']
                    ? visitWebsiteButtonType['urlType'] : false,
            },
          });
        });
      }
      const reqBody = {
        categoryDetails: {
          id: formValue['category']['id'],
        },
        languageDetails: {
          id: formValue['language']
        },
        templateName: formValue['whatsappTemplateName'],
        templateContent: formValue['whatsappTemplateContent'],
        footerText: this.isAuthTemplate ? this.expiryTime : formValue['footer']? formValue['footer']:null,
        templateHeaderDto: {
          id: formValue['headerType'] ? formValue['headerType']['id'] : null,
          headerName: formValue['headerType']
            ? formValue['headerType']['name'] : null,
          text: formValue['header'] ? formValue['header'] : null,
          carouselDtoList: carouselItemsList,
        },
        caroselMediaType: formValue['carouselItems'] ? formValue['carouselItems'][0].carouselMediaType : null,
        templateButtonTypeDto: {
          buttonTypeName: this.isAuthTemplate ? 'Quick reply' : (formValue['buttonsType']
            ? formValue['buttonsType']['name'] : null),
          firstButton:
            this.isAuthTemplate ?  formValue['copyCodeButton']: (formValue['quickReply'] && formValue['quickReply'][0]
              ? formValue['quickReply'][0]['buttonText'] : null),
          secondButton:
            formValue['quickReply'] && formValue['quickReply'][1]
              ? formValue['quickReply'][1]['buttonText'] : null,
          thirdButton:
            formValue['quickReply'] && formValue['quickReply'][2]
              ? formValue['quickReply'][2]['buttonText'] : null,
          callToAction: {
            callPhoneNumberButtontype: {
              buttonSupportTypeName:
                phoneNumberAction && phoneNumberAction['typeOfAction']
                  ? phoneNumberAction['typeOfAction']['name'] : null,
              buttonText:
                phoneNumberAction && phoneNumberAction['buttonText']
                  ? phoneNumberAction['buttonText'] : null,
              phoneNumber:
                phoneNumberAction && phoneNumberAction['phoneNumber']
                  ? phoneNumberAction['phoneNumber'] : null,
            },
            visitWebsiteButtonType: {
              buttonSupportTypeName:
                visitWebsiteAction && visitWebsiteAction['typeOfAction']
                  ? visitWebsiteAction['typeOfAction']['name'] : null,
              buttonText:
                visitWebsiteAction && visitWebsiteAction['buttonText']
                  ? visitWebsiteAction['buttonText'] : null,
              websiteUrl:
                visitWebsiteAction && visitWebsiteAction['websiteUrl']
                  ? visitWebsiteAction['websiteUrl'] : null,
              isLinkTrackingEnabled:
                visitWebsiteAction && visitWebsiteAction['urlType']
                  ? visitWebsiteAction['urlType'] : false,
            },  
          },
          copyOfferCode: formValue['ltoOfferCode'] ? formValue['ltoOfferCode'] : null,
        },
        limitedTimeOfferExperationText: formValue['isltoExpiryCode'] && formValue['isltoExpiryCode'] === this.getTranslatedValues('YES') ? this.getTranslatedValues('OFFER_TEXT'): null,
        limitedTimeOfferMediaType:formValue['ltoMediaType'] ? formValue['ltoMediaType'] : null,
        limitedTimeOfferMediaUrl: this.isLtoSelected && formValue['file'] ? formValue['file'] : null,
        limitedTimeOfferText: formValue['ltoText'] ? formValue['ltoText'] : null,
      };
      let grpData;
      const reqObj = {
        channelId: this.channelId,
        body: {
          templateMetaData: JSON.stringify(reqBody),
          file: this.isLtoSelected ? null : (formValue['file'] ? this.fileForUpload : null),
        },
      };
       grpData = reqObj;
      this.whatsappTemplateControllerService
        .createWhatsAppTemplate(grpData)
        .subscribe(
          (resp) => {
            if (resp) {
              this.submitted = false;
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage(
                'success',
                'WHATSAPP_TEMPLATE_CREATED_SUCCESSFULLY'
              );
            }
            this.onReset();
          },
          (err) => {
            this.submitted = false;
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    }
  }

  public getTemplateDetails() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.dataLoaded.subscribe((result) => {
      if (result) {
        this.whatsappTemplateControllerService
          .fetchWhatsAppTemplateDetails({ templateId: this.templateId })
          .subscribe(
            (resp) => {
              if (resp) {
                const category = resp['categoryDetails'];
                const header = resp['templateHeaderDto'];
                const buttonType = resp['templateButtonTypeDto'];
                const carouselDtoList = header && header['carouselDtoList'] ? header['carouselDtoList'] : null;
                const carouselMediaType = resp['caroselMediaType'];
                const callToAction =
                  buttonType && buttonType['callToAction']
                    ? buttonType['callToAction'] : null;
                const callPhoneNumberButton =
                  buttonType &&
                  callToAction &&
                  callToAction['callPhoneNumberButtontype']
                    ? callToAction['callPhoneNumberButtontype'] : null;
                const visitWebsiteButton =
                  buttonType &&
                  callToAction &&
                  callToAction['visitWebsiteButtonType']
                    ? callToAction['visitWebsiteButtonType'] : null;
                const quickReplyArray = [];
                const callToActionArray = [];
                if(category) {
                  this.updateDropdownValues({
                    child: 'authenticationTemplate',
                    value: { id: category['id'], name: category['name'] },
                  });
                }
                if (header) {
                  this.updateDropdownValues({
                    child: 'header',
                    value: { id: header['id'], name: header['headerName'] },
                  });
                  if (header['headerName'] !== 'Text' && header['headerUrl']) {
                    this.fileUrl = header['headerUrl'];
                  }
                  if(header['headerName'] === 'Limited time offer' && resp['limitedTimeOfferMediaUrl']) {
                    this.fileUrl = resp['limitedTimeOfferMediaUrl'];
                  }
                }

                if (carouselDtoList && carouselDtoList.length > 0) {
                  if (this.carouselFormArray.length > 0) {
                    this.carouselFormArray.clear();
                  }
                  carouselDtoList.forEach((carouselItem: any,index) => {
                    const selectedButtonType = carouselDtoList[index].firstButton ? this.dropDownValues['buttonsType'][0] : this.dropDownValues['buttonsType'][1];
                    
                    const newCarouselItem = new FormGroup({
                      carouselContent: new FormControl(carouselItem.carouselContent, [Validators.required, Validators.maxLength(160)]),
                      carouselMediaType: new FormControl(carouselMediaType, [Validators.required]), 
                      carouselMediaFile: new FormControl(carouselItem.mediaUrl, [Validators.required]),
                      carouselButtonsType: new FormControl(selectedButtonType),
                      carouselQuickReply: this.createQuickReplyFormArray(carouselItem),
                      carouselCallToAction: this.createCallToActionFormArray(carouselItem)
                    });
                    this.carouselFormArray.push(newCarouselItem);
                  });
                }
                
                if (buttonType) {
                  if (buttonType['buttonTypeName'] === 'Quick reply' && !this.isAuthTemplate) {
                    this.updateDropdownValues({
                      child: 'buttonText',
                      value: {
                        id: buttonType['id'],
                        name: buttonType['buttonTypeName'],
                      },
                    });
                    if (buttonType['firstButton']) {
                      quickReplyArray.push({
                        buttonText: buttonType['firstButton'],
                      });
                    }
                    if (buttonType['secondButton']) {
                      this.addFormGroup('quickReply');
                      quickReplyArray.push({
                        buttonText: buttonType['secondButton'],
                      });
                    }
                    if (buttonType['thirdButton']) {
                      this.addFormGroup('quickReply');
                      quickReplyArray.push({
                        buttonText: buttonType['thirdButton'],
                      });
                    }
                  } else if (
                    buttonType['buttonTypeName'] === 'Call to action' &&
                    (visitWebsiteButton || callPhoneNumberButton)
                  ) {
                    this.updateDropdownValues({
                      child: 'buttonText',
                      value: { name: buttonType['buttonTypeName'] },
                    });
                    if (callPhoneNumberButton) {
                      visitWebsiteButton &&
                      callPhoneNumberButton &&
                      callToActionArray.length
                        ? this.addFormGroup('callToActionArr') : '';
                      this.updateDropdownValues({
                        child: 'actionType',
                        value: {
                          name: callPhoneNumberButton['buttonSupportTypeName'],
                          buttontypeId: buttonType['id'],
                        },
                        index: callToActionArray.length ? 1 : 0,
                      });
                      if (callToAction && callPhoneNumberButton) {
                        callToActionArray.push({
                          typeOfAction: {
                            id: callToAction['id'],
                            name: callPhoneNumberButton[
                              'buttonSupportTypeName'
                            ],
                            buttontypeId: buttonType['id'],
                          },
                          buttonText: callPhoneNumberButton['buttonText'],
                          phoneNumber: callPhoneNumberButton['phoneNumber'],
                        });
                      }
                    }
                    if (visitWebsiteButton) {
                      visitWebsiteButton &&
                      callPhoneNumberButton &&
                      callToActionArray.length
                        ? this.addFormGroup('callToActionArr') : '';
                      this.updateDropdownValues({
                        child: 'actionType',
                        value: {
                          name: visitWebsiteButton['buttonSupportTypeName'],
                        },
                        index: callToActionArray.length ? 1 : 0,
                      });
                      if (callToAction && visitWebsiteButton) {
                        callToActionArray.push({
                          typeOfAction: {
                            id: callToAction['id'],
                            name: visitWebsiteButton['buttonSupportTypeName'],
                            buttontypeId: buttonType['id'],
                          },
                          buttonText: visitWebsiteButton['buttonText'],
                          websiteUrl: visitWebsiteButton['websiteUrl'],
                          urlType: visitWebsiteButton['linkTrackingEnabled'],
                        });
                      }
                    }
                  } 
                }
                const formValue = {
                  account: this.accountId,
                  tenant: this.tenantId,
                  businessProfile: this.profileId,
                  channel:this.channelId,
                  language: resp['languageDetails']['id'],
                  category:  {
                        id: resp['categoryDetails']['id'],
                        name: resp['categoryDetails']['name'],
                      },
                  whatsappTemplateName: resp['templateName'],
                  whatsappTemplateContent: resp['templateContent'],
                  headerType: header
                    ? {
                        id: header['id'],
                        name: header['headerName'],
                      }
                    : null,
                  header: header && header['text'] ? header['text'] : null,
                  buttonsType: buttonType
                    ? {
                        id: buttonType['id'],
                        name: buttonType['buttonTypeName'],
                      }
                    : null,
                  quickReply:
                    quickReplyArray && quickReplyArray.length
                      ? quickReplyArray : null,
                  callToActionArr:
                    callToActionArray && callToActionArray.length
                      ? callToActionArray : null,
                  footer: resp['footerText'] ? resp['footerText'] : null,
                  expiryTime: resp['footerText'] ? true : false,
                  securityMessage: this.isAuthTemplate ? (resp['templateContent'] === this.getTranslatedValues('SECURITY_RECOMMENDATION_MSG') ? true : false) : '',
                  copyCodeButton: this.isAuthTemplate ? buttonType.firstButton : '',
                  carouselItems: carouselDtoList && this.carouselFormArray
                                    ? this.carouselFormArray.value: null,
                  ltoText: resp['limitedTimeOfferText'] ? resp['limitedTimeOfferText']: null,
                  ltoMediaType: resp['limitedTimeOfferMediaType'] ? resp['limitedTimeOfferMediaType'] : '',
                  isltoExpiryCode: resp['limitedTimeOfferExperationText'] ? this.getTranslatedValues('YES') : this.getTranslatedValues('NO'),
                  ltoOfferCode: this.isLtoSelected && buttonType['copyOfferCode'] ? buttonType['copyOfferCode'] : null,
                  file : this.fileUrl
                };
                this.footerText = category['name'] === 'AUTHENTICATION' ? true : false;
                this.characterFooterCount = resp['footerText']?.length;
                this.characterContentCount = resp['templateContent'].length;
                this.expiryTime = this.isAuthTemplate ? resp['footerText'] : '';
                this.templateForm.patchValue(formValue);
                this.loaderService.hideProgressBar(this.showSpinner);
              }
            },
            (err) => {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage(
                'error',
                err.error.message
              );
            }
          );
      }
    });
  }

  public openUploadDialog(content,index) {
    this.invalidFile = true;
    this.carouselIndex = index;
    this._matDialog.open(content, { disableClose: true });
  }

  public selectFiles(event) {
    if (event.target.files && event.target.files.length > 0) {
      if (this.validateMediaType(event.target.files[0])) {
        /** Code for Validating the duration and size of the video might be required in future
        if( this.validateMediaSize(event.target.files[0],this.supportedMediaType === this.videoFormat?2097152:20000)) {
           if(this.supportedMediaType === this.videoFormat) {
            this.checkValidDuration(event.target.files[0]).then(() => {
              this.invalidFile = false;
              this.fileForUpload = event.target.files[0];
            }).catch((error) => {
              this.invalidFile = true;
              this.responseHandlerService.returnToastMessage('error',`THE_MAXIMUM_DURATION_OF_VIDEO_SHOULD_NOT_BE_GREATER_THAN_30_SECONDS`);
            });
          } else {
            this.fileForUpload = event.target.files[0];
          }
        } else {
          if(this.supportedMediaType === this.videoFormat) {
            this.responseHandlerService.returnToastMessage('error',`THE_MAXIMUM_SIZE_OF_VIDEO_SHOULD_NOT_BE_GREATER_THAN_16_MB`);
          } else {
            this.responseHandlerService.returnToastMessage('error',`THE_MAXIMUM_SIZE_OF_IMAGE_SHOULD_NOT_BE_GREATER_THAN_16_MB`);
          }
        }*/

        if(this.supportedMediaType === this.videoFormat && !this.validateMediaSize(event.target.files[0], 104857600)) {
          this.responseHandlerService.returnToastMessage('error','THE_MAXIMUM_SIZE_OF_VIDEO_SHOULD_NOT_BE_GREATER_THAN_100_MB');
        } else {
          this.fileForUpload = event.target.files[0];
          const reader = new FileReader();
          reader.onload = () => {
            this.fileUrl = reader.result as string;
          };
          reader.readAsDataURL(this.fileForUpload);
        }
      } else {
        this.responseHandlerService.returnToastMessage(
          'error',
          `PLEASE_UPLOAD_VALID_${
            this.supportedMediaType === this.imageFormat
              ? 'IMAGE'
              : this.supportedMediaType === this.videoFormat
              ? 'MP4_OR_M4V'
              : 'PDF'
          }_FILE`
        );
      }
    }
  }

  public validateMediaType(file) {
    if (this.supportedMediaType) {
      const mediaTypes = this.supportedMediaType.split(',');
      if (mediaTypes.includes(`.${file.name.split('.').pop()}`)) {
        this.invalidFile = false;
        return true;
      } else {
        this.invalidFile = true;
        return false;
      }
    }
  }

  /** Code for Validating the duration and size of the video might be required in future
  public validateMediaSize(file,size) {
    if(file.size <= size) {
      this.invalidFile = false;
      return true;
    } else {
      this.invalidFile = true;
      return false;
    }
  }


  public checkValidDuration(file) {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = function() {
          if(video.duration < 30) {
            resolve(true);
          } else {
            reject(false);
          }
        };
    });
  } */

  public validateMediaSize(file,size) {
    if(file.size <= size) {
      this.invalidFile = false;
      return true;
    } else {
      this.invalidFile = true;
      return false;
    }
  }

  public fileUpload() {
    if(this.isCarouselSelected || this.isLtoSelected) {
      let reqObj;
      if (this.fileUrl) {
        this.showSpinner = this.loaderService.showProgressBar();
        const params = {
          apiKey: this.secretKey,
          body: {
            file: this.fileForUpload
          }
        };
        reqObj = params;
        this.incomingMessageControllerService.uploadFileAndReturnUrl(reqObj).subscribe(
          (resp) => {
            if (resp && resp['length']) {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.mediaURL = resp; 
              if(this.isCarouselSelected) {
                const carouselItems = this.templateForm.get('carouselItems') as FormArray;
                const carouselItem = carouselItems.at(this.carouselIndex) as FormGroup;
                carouselItem.get('carouselMediaFile').setValue(this.mediaURL);
                this.carouselMediaFileNames[this.carouselIndex] = this.fileForUpload['name'];
              } else {
                this.templateForm.controls['file'].setValue(this.mediaURL);
              }
            }
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
      }
    } else {
      this.templateForm.controls['file'].setValue(this.fileUrl);
    }
    this.uploadedFileName = this.fileForUpload['name'];
    this.onClose();
  }

  public downloadFile(fileURL) {
    if (this.fileUrl) {
      window.open(this.fileUrl, '_blank');
    }
    if(fileURL) {
      window.open(fileURL, '_blank');
    }
  }

  public onClose() {
    this._matDialog.closeAll();
  }

  public addFormGroup(formArrayName) {
    if (formArrayName === 'quickReply') {
      const items = this.templateForm.get('quickReply') as FormArray;
      items.push(
        new FormGroup({
          buttonText: this.getFormControl(true, 25),
        })
      );
    } else if(formArrayName === 'carouselItems') {
      this.showEmojiPickerCarousel = false;
      const items = this.templateForm.get('carouselItems') as FormArray;
      const lastCarouselIndex = items.length - 1;
      const lastCarouselItem = items.at(lastCarouselIndex) as FormGroup;
      lastCarouselItem.markAllAsTouched();
      lastCarouselItem.updateValueAndValidity();
      const firstCarouselItem = items.at(0) as FormGroup;
      const carouselMediaType = firstCarouselItem.get('carouselMediaType').value;
      const carouselButtonsType = firstCarouselItem.get('carouselButtonsType').value;
      
      const quickReplyArray = new FormArray([]);
      const callToActionArray = new FormArray([]);
      
      if(carouselButtonsType['name'] === 'Quick reply') {
        const carouselQuickReplyFormArray = firstCarouselItem.get('carouselQuickReply').value;
        carouselQuickReplyFormArray.forEach(item => {
          quickReplyArray.push(
            new FormGroup({
              buttonText: this.getFormControl(true,25)
            })
          );
        });
      }

      if(carouselButtonsType['name'] === 'Call to action') {
        const carouselCallToActionFormArray = firstCarouselItem.get('carouselCallToAction').value;

        carouselCallToActionFormArray.forEach((item) => {
          if (item['typeOfAction']['name'] === 'Call Phone Number') {
            const callToActionGroup = new FormGroup({
                typeOfAction: new FormControl(item['typeOfAction'], Validators.required),
                buttonText: this.getFormControl(true, 25),
                phoneNumber: new FormControl('', [
                  Validators.required,
                  Validators.pattern(/^\+[1-9][0-9]{1,14}$/),
                ])
              });
              callToActionArray.push(callToActionGroup);
          } else if (item['typeOfAction']['name'] === 'Visit Website') {
            const callToActionGroup = new FormGroup({
              typeOfAction: new FormControl(item['typeOfAction'], Validators.required),
              buttonText: this.getFormControl(true, 25),
              websiteUrl: new FormControl('', [
                Validators.required,
                Validators.pattern('^(http:\/\/|https:\/\/)(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$')])  
            });
            callToActionArray.push(callToActionGroup);
          }
        });
      }
      
      if(lastCarouselItem.valid) { 
        items.push(
          new FormGroup({
            carouselContent: this.getFormControl(true, 160),
            carouselMediaType: new FormControl(carouselMediaType, [Validators.required]),
            carouselMediaFile: this.getFormControl(),
            carouselButtonsType: new FormControl(carouselButtonsType),
            carouselQuickReply: quickReplyArray,
            carouselCallToAction: callToActionArray
          })
        );
      } 
    
      this.characterContentCounts.push(0);
      setTimeout(() => {
        const swiperInstance = this.swiperRef.nativeElement.swiper;
        const newIndex = items.length - 1;
        swiperInstance.slideTo(newIndex);
      }, 0); 
    } else if (formArrayName === 'callToActionArr') {
      const typeOfActionTwo = this.dropDownValues['typeOfAction'].find(
        (resp) => resp['name'] !== this.selectedTypeOfActionOne
      );
      const items = this.templateForm.get('callToActionArr') as FormArray;
      items.push(
        new FormGroup({
          typeOfAction: new FormControl(typeOfActionTwo, Validators.required),
        })
      );
      const setOption = {
        child: 'actionType',
        value: typeOfActionTwo,
        index: 1,
      };
      if (!this.disablePointer) {
        this.updateDropdownValues(setOption);
      }
    } else if(formArrayName === 'carouselQuickReply') {
      const carouselItems = this.templateForm.get('carouselItems') as FormArray;
      carouselItems.controls.forEach((item) => {
        const carouselButtonDetails = item.get('carouselQuickReply') as FormArray;
        carouselButtonDetails.push(
          new FormGroup({
            buttonText: this.getFormControl(true, 25),
          })
        );
      });
    } else if(formArrayName === 'carouselCallToAction') {
      const typeOfActionTwo = this.dropDownValues['typeOfAction'].find(
        (resp) => resp['name'] !== this.selectedTypeOfActionOne
      );
      const carouselItems = this.templateForm.get('carouselItems') as FormArray;
      carouselItems.controls.forEach((item) => {
        const carouselButtonDetails = item.get('carouselCallToAction') as FormArray;
        carouselButtonDetails.push(
          new FormGroup({
            typeOfAction: new FormControl(typeOfActionTwo, Validators.required),
            buttonText: this.getFormControl(true, 25),
          })
        );
      });
      const setOption = {
        child: 'actionType',
        value: typeOfActionTwo,
        index: 1,
      };
      if (!this.disablePointer) {
        this.updateDropdownValues(setOption);
      }
    }
  }

  public removeFormGroup(formArrayName, indexNumber) {
    (<FormArray>this.templateForm.get(formArrayName)).removeAt(indexNumber);
    if (formArrayName === 'callToActionArr') {
      if (indexNumber === 0) {
        if (this.selectedTypeOfActionTwo) {
          this.selectedTypeOfActionOne = this.selectedTypeOfActionTwo;
          this.selectedTypeOfActionTwo = null;
        } else {
          this.selectedTypeOfActionOne = null;
        }
      } else {
        this.selectedTypeOfActionTwo = null;
      }
    }
    if(formArrayName === 'carouselItems') {
      this.characterContentCounts.splice(indexNumber, 1);
    }
  }

  public removeCarouselFormGroup(formControlName, indexNumber) {
    const carouselItems = this.templateForm.get('carouselItems') as FormArray;
      if(formControlName === 'quickReply') {
        carouselItems.controls.forEach((item) => {
          const carouselButtonDetails = item.get('carouselQuickReply') as FormArray;
          if (carouselButtonDetails && carouselButtonDetails.length > indexNumber) {
            carouselButtonDetails.removeAt(indexNumber); 
        }
      });
    } else if(formControlName === 'callToAction') {
      carouselItems.controls.forEach((item) => {
        const carouselButtonDetails = item.get('carouselCallToAction') as FormArray;
        if (carouselButtonDetails && carouselButtonDetails.length > indexNumber) {
          carouselButtonDetails.removeAt(indexNumber); 
        }
      });
      if (indexNumber === 0) {
        if (this.selectedTypeOfActionTwo) {
          this.selectedTypeOfActionOne = this.selectedTypeOfActionTwo;
          this.selectedTypeOfActionTwo = null;
        } else {
          this.selectedTypeOfActionOne = null;
        }
      } else {
        this.selectedTypeOfActionTwo = null;
      }
    }
  }

  public getFormControl(required = true, maxLength = null) {
    if (required && !maxLength) {
      return new FormControl('', Validators.required);
    } else if (required && maxLength) {
      return new FormControl('', [
        Validators.required,
        Validators.maxLength(maxLength),
      ]);
    } else if (!required && maxLength) {
      return new FormControl('', [Validators.maxLength(maxLength)]);
    } else {
      return new FormControl('');
    }
  }

  public addFormControl(controlName, control) {
    this.templateForm.addControl(controlName, control);
  }

  public removeFormControl(controlName) {
    this.templateForm.removeControl(controlName);
  }

  public removeControlsOnReset() {
    this.removeFormControl('file');
    this.removeFormControl('header');
    this.templateForm.controls['quickReply']
      ? this.templateForm.controls['quickReply'].reset()
      : '';
    this.quickReplyFormArray = new FormArray([
      new FormGroup({
        buttonText: this.getFormControl(true, 25),
      }),
    ]);
    this.templateForm.controls['callToActionArr']
      ? this.templateForm.controls['callToActionArr'].reset()
      : '';
    this.callToActionFormArray = new FormArray([
      new FormGroup({
        typeOfAction: this.getFormControl(),
      }),
    ]);
    this.removeFormControl('quickReply');
    this.removeFormControl('callToActionArr');
    this.characterFooterCount = 0;
    this.characterContentCount = 0;
    this.removeFormControl('setExpiryTime');
    this.disableFooterPointer = false;
    this.disableSecurityPointer = false;
    this.disableExpiryPointer = false;
    this.isAuthTemplate = false;
    this.isCarouselSelected = false;
    this.isLtoSelected = false;
    this.removeFormControl('carouselItems');
    this.removeFormControl('ltoText');
    this.removeFormControl('ltoMediaType');
    this.removeFormControl('isltoExpiryCode');
    this.removeFormControl('ltoOfferCode');
    this.addFormControl('footer', this.getFormControl(false, 60));
    this.addFormControl('buttonsType', this.getFormControl(false));
    this.addFormControl('headerType', this.getFormControl(false));
  }

  public onReset() {
    this.submitted = false;
    this.selectedTypeOfActionOne = null;
    this.selectedTypeOfActionTwo = null;
    this.templateForm.reset({
      account: this.accountId,
      tenant: this.tenantId,
      businessProfile: this.profileId,
      channel:this.channelId
    });
    this.removeControlsOnReset();
    this.fileForUpload = null;
    this.fileUrl = null;
    this.uploadedFileName = null;
    this.carouselMediaFileNames = [];
  }

  public navigateBack() {
    this.router.navigate([
      'whatsapp/template-configuration',
      {
        accountId: this.accountId,
        tenantId: this.tenantId,
        profileId: this.profileId,
        channelId:this.channelId
      },
    ]);
  }

  public loadRouteData() {
    if (this.accountId && this.tenantId && this.profileId) {
      this.getTenant(this.accountId);
      this.getProfile(this.tenantId);
      this.getChannel(this.profileId);
      if (!this.templateId) {
        this.templateForm.patchValue({
          account: this.accountId,
          tenant: this.tenantId,
          businessProfile: this.profileId,
          channel: this.channelId,
        });
      } else if (this.templateId) {
        this.disablePointer = true;
        this.getTemplateDetails();
      }
    }
  }

  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getTemplateCategories() {
    this.whatsappTemplateControllerService
      .whatsAppTemplateCategories({})
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['category'] = resp;
          } else {
            this.dropDownValues['category'] = [];
          }
        },
        (err) => {
          this.dropDownValues['category'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getButtonsType() {
    this.whatsappTemplateControllerService.getButtonType({}).subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.dropDownValues['buttonsType'] = resp;
        } else {
          this.dropDownValues['buttonsType'] = [];
        }
      },
      (err) => {
        this.dropDownValues['buttonsType'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTypeOfActions() {
    this.whatsappTemplateControllerService.getButtonTypeSupportList().subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.callPhoneType = resp.find(
            (element) => element['name'] === 'Call Phone Number'
          );
          this.dropDownValues['typeOfAction'] = resp;
          this.dataLoaded.next(true);
        } else {
          this.dropDownValues['typeOfAction'] = [];
        }
      },
      (err) => {
        this.dropDownValues['typeOfAction'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getLanguages() {
    this.whatsappTemplateControllerService.getMessageLanguage({}).subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.dropDownValues['language'] = resp;
        } else {
          this.dropDownValues['language'] = [];
        }
      },
      (err) => {
        this.dropDownValues['language'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getHeaderType() {
    this.whatsappTemplateControllerService.getHeaderType({}).subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.dropDownValues['headerType'] = resp;
        } else {
          this.dropDownValues['headerType'] = [];
        }
      },
      (err) => {
        this.dropDownValues['headerType'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public compareFn(option, value) {
    if (option && value && option.name && value.name) {
      return option.name === value.name;
    }
  }
  
  public onTextInputChange(isCarousel:boolean, index?:number) {
   this.updateCharacterCount(isCarousel,index);
  }

  public onTextInputFooterChange() {
    this.characterFooterCount = this.templateForm.controls.footer.value.length;
  }

  public ngOnDestroy() {
    this.dataLoaded.unsubscribe();
  }
  
  toggleEmojiPicker(isCarousel?:boolean) {
    if(!isCarousel) {
      this.showEmojiPicker = !this.showEmojiPicker;
    } else {
      this.showEmojiPickerCarousel = !this.showEmojiPickerCarousel;
    }
  }

  addEmoji(event,isCarousel:boolean, index?:number) {
    if(!isCarousel) {
      const messageControl = this.templateForm.get('whatsappTemplateContent') as FormControl;
      if (messageControl) {
        const currentMessage = messageControl.value || '';
        const updatedMessage = `${currentMessage}${event.emoji.native}`;
        if (updatedMessage.length <= 1024) {
          messageControl.setValue(updatedMessage);
        }
      }
      this.updateCharacterCount(isCarousel);
      this.showEmojiPicker = false;
    } else {
      const carouselItems = this.templateForm.get('carouselItems') as FormArray;
      const carouselItem = carouselItems.at(index) as FormGroup;
      const messageContent = carouselItem.get('carouselContent') as FormControl;
      if (messageContent) {
        const currentMessage = messageContent.value || '';
        const updatedMessage = `${currentMessage}${event.emoji.native}`;
        if (updatedMessage.length <= 160) {
          messageContent.setValue(updatedMessage);
        }
      }
      this.updateCharacterCount(isCarousel,index);
      this.showEmojiPickerCarousel = false;
    }
  }

  updateCharacterCount(isCarousel:boolean, index?:number) {
    if(!isCarousel) {
      const messageControl = this.templateForm.get('whatsappTemplateContent') as FormControl;
      if (messageControl) {
        this.characterContentCount = messageControl.value.length;
      }
    } else if(isCarousel) {
      const carouselItems = this.templateForm.get('carouselItems') as FormArray;
      const carouselItem = carouselItems.at(index) as FormGroup;
      const messageContent = carouselItem.get('carouselContent') as FormControl;
      if (messageContent) {
        const count = messageContent.value.length;
        this.characterContentCounts[index] = count;
      }
    }
  }

  onCheckboxChange(event: any, data) {
    const checkboxValue = event.checked;
    if(data === 'footer') {
      this.templateForm.controls.footer.setValue(checkboxValue ? this.getTranslatedValues('REPLY_STOP_TO_UNSUBSCRIBE') : '');
      this.disableFooterPointer = checkboxValue;
      this.characterFooterCount = this.templateForm.controls.footer.value.length;
    }
    else if (data === 'addSecurityMsg') {
      const messageContent = checkboxValue ? this.getTranslatedValues('SECURITY_RECOMMENDATION_MSG') : this.getTranslatedValues('AUTH_TEMPLATE_MSG');
      this.templateForm.get('whatsappTemplateContent').setValue(messageContent);
      this.disableSecurityPointer = checkboxValue;
    }
    else {
      this.disableExpiryPointer = checkboxValue;
      if(checkboxValue) {
        this.addFormControl('setExpiryTime', new FormControl(10, [
          Validators.required,
          Validators.min(1),
          Validators.max(90)
        ]));
        const message  = this.getTranslatedValues('EXPIRY_TIME_MSG') + this.templateForm.get('setExpiryTime').value + this.getTranslatedValues('MINUTES');
        this.expiryTime = checkboxValue ? message : '';
      } else {
        this.removeFormControl('setExpiryTime');
      }    
    }
  }

  onMediaTypeChange(event: any) { 
    if(this.isCarouselSelected) {
      this.carouselMediaFileNames = [];
      const carouselItems = this.templateForm.get('carouselItems') as FormArray;
      carouselItems.controls.forEach((carouselItemGroup) => {
        const carouselItem = carouselItemGroup as FormGroup;
        const mediaFileControl = carouselItem.get('carouselMediaFile');
        mediaFileControl.reset();
      });
      const mediaType = this.templateForm.get(`carouselItems.${0}.carouselMediaType`).value;
      this.supportedMediaType = mediaType === 'image' ? this.imageFormat : this.videoFormat;
    }
    if(this.isLtoSelected) {
      this.fileUrl = null;
      this.fileForUpload = null;
      this.uploadedFileName = null;
      const ltoMediaType = event.value;
      if(ltoMediaType) {
        this.addFormControl('file', this.getFormControl());
        this.supportedMediaType = ltoMediaType === 'image' ? this.imageFormat : this.videoFormat;
      } else {
        this.removeFormControl('file');
      }
    }
  }

  onExpiryTimeChange(event: Event): void {
     const expiryControl = this.templateForm.get('setExpiryTime');
     if (expiryControl) {
       const message = this.getTranslatedValues('EXPIRY_TIME_MSG') + expiryControl.value + ' minutes';
       this.expiryTime = message;
     } 
     else {
      this.expiryTime = '';
    }
  }

  public getTranslatedValues(key: string) {
    let translatedValue;
    this.translate.get(key).subscribe((translatedKey: string) => {
      translatedValue = translatedKey;
    });
    return translatedValue;
  }
  
  getSecretKey() {
    this.agentControllerService.getSecretKey().subscribe(resp => {
     this.secretKey = resp;
    });
  }

  public getChannel(profileId: string) {
    this.whatsappProfileConnectionDetailsControllerService
      .fetchProfileMappedChannels({ profileId: profileId })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            const response = resp.map(item => ({
              'id': item.id,
              'name': item.channelName
            }));
            this.dropDownValues['channel'] = response;
          } else {
            this.dropDownValues['channel'] = [];
          }
        },
        (err) => {
          this.dropDownValues['channel'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  onPreviewSlideChange(event: any) {
    this.showEmojiPickerCarousel = false;
    const [swiperInstance] = event.detail; 
    const activeIndex = swiperInstance.activeIndex; 
    const previewSwiperInstance = this.previewSwiperRef.nativeElement.swiper;
    previewSwiperInstance.slideTo(activeIndex); 
  }

  private createQuickReplyFormArray(carouselItem: any): FormArray {
    const quickReplyArray = new FormArray([]);
    if (carouselItem.firstButton) {
      quickReplyArray.push(
        new FormGroup({
          buttonText: new FormControl(carouselItem.firstButton, [Validators.required]),
        })
      );
    }
    
    if (carouselItem.secondButton) {
      quickReplyArray.push(
        new FormGroup({
          buttonText: new FormControl(carouselItem.secondButton, [Validators.required]),
        })
      );
    }
    return quickReplyArray;
  }

  private createCallToActionFormArray(carouselItem: any): FormArray {
    const callToActionArray = new FormArray([]);
    if (carouselItem.callPhoneNumberButtontype) {
      callToActionArray.push(new FormGroup({
        typeOfAction: new FormControl(this.dropDownValues['typeOfAction'][0], [Validators.required]),
        buttonText: new FormControl(carouselItem.callPhoneNumberButtontype.buttonText, [Validators.required]),
        phoneNumber: new FormControl(carouselItem.callPhoneNumberButtontype.phoneNumber, [
          Validators.required,
          Validators.pattern(/^\+[1-9][0-9]{1,14}$/)
        ])
      }));
    }
  
    if (carouselItem.visitWebsiteButtonType) {
      callToActionArray.push(new FormGroup({
        typeOfAction: new FormControl(this.dropDownValues['typeOfAction'][1], [Validators.required]),
        buttonText: new FormControl(carouselItem.visitWebsiteButtonType.buttonText, [Validators.required]),
        websiteUrl: new FormControl(carouselItem.visitWebsiteButtonType.websiteUrl, [
          Validators.required,
          Validators.pattern('^(http:\/\/|https:\/\/)(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$')
        ]),
        linkTrackingEnabled: new FormControl(carouselItem.visitWebsiteButtonType.linkTrackingEnabled)
      }));
    }
  
    return callToActionArray;
  }
  
}
