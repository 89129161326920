/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class IncomingMessageControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation uploadFileAndReturnUrl
   */
  static readonly UploadFileAndReturnUrlPath = '/api/v1/upload/media-files';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFileAndReturnUrl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadFileAndReturnUrl$Response(params: {
    apiKey: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, IncomingMessageControllerService.UploadFileAndReturnUrlPath, 'post');
    if (params) {
      rb.header('apiKey', params.apiKey, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
     responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadFileAndReturnUrl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadFileAndReturnUrl(params: {
    apiKey: string;
    body?: {
'file'?: Blob;
}
  }): Observable<string> {

    return this.uploadFileAndReturnUrl$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation incomingMessages
   */
  static readonly IncomingMessagesPath = '/api/v1/incoming-message';

  /**
   * CallBack API.
   *
   * Call back API from Gupshup to save incoming messages
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `incomingMessages()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  incomingMessages$Response(params: {
    apiKey: string;
    interactionType: string;
    body: string
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, IncomingMessageControllerService.IncomingMessagesPath, 'post');
    if (params) {
      rb.header('apiKey', params.apiKey, {});
      rb.query('interactionType', params.interactionType, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * CallBack API.
   *
   * Call back API from Gupshup to save incoming messages
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `incomingMessages$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  incomingMessages(params: {
    apiKey: string;
    interactionType: string;
    body: string
  }): Observable<void> {

    return this.incomingMessages$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
